import axios from "axios";
import { fetchingPropsI } from "./request/request.interface";

export interface AuthTokens {
    accessToken: string;
    refreshToken: string;
}

export const sendGoogleLogin = async ({ params, signal }: fetchingPropsI) => {
    const uri = process.env.REACT_APP_API + "auth/login-google";
    const response = await axios.post(uri, params)
    return response.data as AuthTokens
}

export const refresh = async (refresh: string) => {
    const uri = process.env.REACT_APP_API + "auth/refresh";
    const response = await axios.get(uri, {
        headers: {
            "Authorization": `Bearer ${refresh}`,
            "withCredentials": "true",
        }
    })
    return response.data as AuthTokens
}
