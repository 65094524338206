import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GOOGLE_CLIENT_ID } from "../../config";
import useController from "../../hooks/useController";
import useUtils from "../../hooks/useUtils";
import { loginSchema } from "../../schemas/auth";
import { login } from "../../store/slices/authSlice";
import { formatError } from "../utils/ToastFormatter";
import { sendGoogleLogin } from "services/auth";
import { COOKIES_NAME } from "constants/appConstants";
import { RootState } from "store/store";
import { PRIVATE_ROUTE } from "routes/privateRoutes";

const SignInForm = ({ withTraditionalForm }: any) => {
  const { setShowLoadingOverlay } = useUtils();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/inicio";
  const controller = useController();
  const dispatch = useDispatch();

  const auth = useSelector((state: RootState) => state.authData);

  useEffect(() => {
    if (auth.isLogged) {
      navigate(PRIVATE_ROUTE.INICIO, { replace: true });
    }
  }, [auth]);

  const onSubmit = async (values: any, actions: any) => {
    // axiosInstance ESTA DESACTUALIZADO USAR METODOS DENTRO DE SERVICE PARA FETCHING
    // setShowLoadingOverlay(true);
    // try {
    //   const response = await axiosInstance.httpApiPost({
    //     url: "auth/login",
    //     data: { email: values.email.toLowerCase(), password: values.password },
    //     controller: controller.current,
    //   });
    //   if (response) {
    //     const { accessToken, refreshToken } = response.data;
    //     const { user, instituciones } = jwtDecode(accessToken);
    //     Cookies.set('notify', JSON.stringify({ accessToken, refreshToken, instituciones }))

    //     dispatch(login({ accessToken, user, refreshToken }));
    //     navigate(from, { replace: true });
    //   }
    // } catch (err) {
    //   formatError(err, "Hubo un problema al iniciar sesión");
    // }
    // finally {
    //   setShowLoadingOverlay(false);
    // }
  };

  const onSubmitGoogle = async (data: any) => {
    setShowLoadingOverlay(true);
    try {
      const response = await sendGoogleLogin({ params: { credential: data.credential } })
      console.log({ response })
      Cookies.set(COOKIES_NAME, JSON.stringify(response))
      dispatch(login(response));
    } catch (err) {
      formatError(err, "Hubo un problema al iniciar sesión");
    }
    finally {
      setShowLoadingOverlay(false);
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "vivia.rodriguez@bue.edu.ar",
      password: "user",
    },
    validationSchema: loginSchema,
    onSubmit,
  });

  return (
    <>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <GoogleLogin
          onSuccess={async (credentialResponse) => {
            await onSubmitGoogle(credentialResponse);
          }}
          onError={() => {
            formatError("Hubo un problema al iniciar sesión");
          }}
        />
      </GoogleOAuthProvider>
      {withTraditionalForm && (
        <>
          {/* 
          <hr></hr>
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="d-flex justify-content-center">
              <div className="form-group mt-3">
                <CustomInput
                  handleChange={handleChange}
                  title={"Correo electrónico"}
                  id="email"
                  type="email"
                  placeholder="email@ejemplo.com"
                  values={values}
                  className="form-control"
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="form-group">
                <CustomInput
                  handleChange={handleChange}
                  title={"Contraseña"}
                  id="password"
                  type="password"
                  placeholder="Contraseña"
                  isPassword
                  values={values}
                  className="form-control"
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />
                <div className="w-100 text-end mt-1">
                  <Link className="small" to={PUBLIC_ROUTE.FORGOT}>
                    ¿Olvidó su contraseña?
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn btn-light btn-block my-3 rounded-pill"
              >
                Iniciar sesión
              </button> */}
          {/* <h6 className="small">
              ¿No tiene una cuenta?
              <Link className="ms-2 small" to={PUBLIC_ROUTE.REGISTER}>
                Crear una ahora
              </Link>
            </h6> */}
          {/* </div>
          </form> */}
        </>
      )}
    </>
  );
};

export default SignInForm;
