import useHasPermission from 'hooks/useHasPermission'
import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { Permisos } from 'types/Permisos'

interface Props {
    permissions: Permisos
    children: ReactNode
}

export const ComponentGuard = ({ permissions = [], children }: Props) => {
    const hasPermission = useHasPermission(permissions)

    return <>
        {hasPermission
            ? children
            : <></>
        }
    </>

}
