import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { Permisos } from "types/Permisos";

/**
 * @param requiredPermissions Array de los permisos requeridos
 * @returns Boolean
 */
export const hasPermissions = (requiredPermissions: Permisos = [], userPermissions: Permisos = [] ) => {

  if ( requiredPermissions.length === 0
    ||  !userPermissions 
    ||  userPermissions.length === 0
  ) {
    return false;
  }
  return requiredPermissions.every((p) => userPermissions?.includes(p));
};

