import { infoFetchGetNotificacionI, makeNotificacion } from "dtos/notificacion"
import { ENDPOINTS } from "./endpoints"
import { sendRequest } from "./request/request.service"
import { servicesUtils } from "./utils/services.utils"
import { getResponseWithPagination } from "./request/request.interface"
import { infoFetchGetNovedadI, makeNovedad } from "dtos/novedad"

export const getNovedadById = async (id: number | string) => {
    const elemento = await sendRequest<infoFetchGetNovedadI>({
        method: "GET",
        uri: ENDPOINTS.NEWS_BY_ID + id,
    })
    return elemento ? makeNovedad(elemento) : undefined
}

export const getNovedades = async (params: any) => {

    const response = await sendRequest<getResponseWithPagination<infoFetchGetNovedadI>>({
        method: "GET",
        uri: ENDPOINTS.NEWS,
        params: servicesUtils.removeNullParamsInFilters(params),
    })

    const elementosFormated = response.elementos.map((ctElemento) => makeNovedad(ctElemento))
    return {
        ...response,
        elementos: elementosFormated,
    }
}

export const postNovedad = async (params: any) => {
    const response = await sendRequest<infoFetchGetNovedadI>({
        method: "POST",
        uri: ENDPOINTS.NEWS_CREATE,
        params: servicesUtils.removeNullParamsInFilters(params),
    })

    return response
}

export const putNovedad = async (params: any) => {
    const response = await sendRequest<infoFetchGetNovedadI>({
        method: "PUT",
        uri: ENDPOINTS.NEWS_EDIT,
        params: servicesUtils.removeNullParamsInFilters(params),
    })

    return response
}

export const deleteNovedad = async (id: any) => {
    const response = await sendRequest({
        method: "DELETE",
        uri: ENDPOINTS.NEWS_DELETE + id,
    });
    return response;
};

export const getNovedadesByUserId = async (id: any, params: any) => {
    const response = await sendRequest<getResponseWithPagination<infoFetchGetNovedadI>>({
        method: "GET",
        uri: ENDPOINTS.NEWS_BY_USER_ID + id,
        params: servicesUtils.removeNullParamsInFilters(params),
    })
    // return response;
    const elementosFormated = response.elementos.map((ctElemento) => makeNovedad(ctElemento))
    return {
        ...response,
        elementos: elementosFormated,
    }
}