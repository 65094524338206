import { EstadoNotificacionI } from "interfaces/EstadoNotificacion";

export interface infoFetchGetEstadoNotificacionI {
    id: number | string;
    name: string;
    description: string;
    borderColor: string;
    backgroundColor: string;
    color: string;
}

class EstadoNotificacion implements EstadoNotificacionI {
    id: number | string;
    name: string;
    description: string;
    borderColor: string;
    backgroundColor: string;
    color: string;

    constructor(infoFetch: infoFetchGetEstadoNotificacionI) {
        this.id = infoFetch.id;
        this.name = infoFetch.name;
        this.description = infoFetch.description;
        this.borderColor = infoFetch.borderColor;
        this.backgroundColor = infoFetch.backgroundColor;
        this.color = infoFetch.color;
    }
}

export const makeEstadoNotificacion = (infoFetch: infoFetchGetEstadoNotificacionI) => {
    return new EstadoNotificacion(infoFetch)
}