import { ReactNode, createContext, useState } from "react";

export interface AuthContextType {
  auth: any;
  setAuth: React.Dispatch<React.SetStateAction<any>>;
}

const AuthContext = createContext<AuthContextType>({
  auth: null,
  setAuth: () => { },
});

interface PropsI {
  children: ReactNode;
}

export const AuthProvider = ({ children }: PropsI) => {
  const [auth, setAuth] = useState({});

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthContext;




