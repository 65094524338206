import { OptionsIcon } from 'assets/icons/OptionsIcon';
import { ModalDelete } from 'components/ModalDelete/ModalDelete';
import { NotificacionI } from 'interfaces/Notificacion';
import { useState } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import { formatDate } from 'utils/formatUtils';
interface Props {
    notificacion: NotificacionI
    handleShowDetailModal: (id: number | string) => void,
    handleDelete: (id: number | string) => void,
    handleEdit: (notificacion: NotificacionI) => void,
}


export const NotificacionesRow = ({ notificacion, handleShowDetailModal, handleDelete, handleEdit }: Props) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleShowDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleConfirmDelete = () => {
        handleDelete(notificacion.id);
        handleCloseDeleteModal();
    };
    return (
        <>
            <tr className='table-row' key={`NotificacionesTable-${notificacion.title}`}>
                <td>{notificacion.title}</td>
                <td>{notificacion.notificationType?.name}</td>
                <td>{formatDate.stringDate(notificacion.sendAt)}</td>
                <td>
                    <Badge bg={"none"}
                        style={{
                            color: notificacion.notificationState?.color,
                            backgroundColor: notificacion.notificationState?.backgroundColor,
                            borderColor: notificacion.notificationState?.borderColor,
                        }}>
                        {notificacion.notificationState?.name}
                    </Badge>
                </td>
                <td>
                    <Dropdown className="acciones-dropdown | d-flex justify-content-center">
                        <Dropdown.Toggle>
                            <OptionsIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleShowDetailModal(notificacion.id)}>Ver notificacion</Dropdown.Item>
                            {notificacion.isEditable && <Dropdown.Item onClick={() => handleEdit(notificacion)}>Editar</Dropdown.Item>}
                            {notificacion.isEditable && <Dropdown.Item onClick={handleShowDeleteModal}>Eliminar</Dropdown.Item>}
                            {/* <Dropdown.Item href="#/action-3">Reporte</Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>

            <ModalDelete
                showModal={showDeleteModal}
                handleCloseModal={handleCloseDeleteModal}
                handleConfirmDelete={handleConfirmDelete}
                isUserDelete={false}
            />
        </>
    )
}
