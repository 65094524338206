import { JwtPayload } from "interfaces/jwtpayload";
import { jwtDecode } from "jwt-decode";

const removeNullParamsInFilters = (filters: { [key: string]: string } = {}) => {
    const objKeys = Object.keys(filters)
    const formatedFilters: { [key: string]: string } = { ...filters }

    objKeys.forEach(ctFilter => {
        if (filters[ctFilter] === null || filters[ctFilter] === undefined || filters[ctFilter] === "") delete formatedFilters[ctFilter]
    });

    return formatedFilters
}

const isTokenValid = (token: string) => {
    const decodedToken = jwtDecode<JwtPayload>(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decodedToken.exp > currentTime;
}

export const servicesUtils = {
    removeNullParamsInFilters,
    isTokenValid
}