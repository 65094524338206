import { LISTA_COLORES_ICONOS } from './LISTA_COLORES_ICONOS';

interface Props {
    color?: (typeof LISTA_COLORES_ICONOS)[keyof typeof LISTA_COLORES_ICONOS];
    size?: number;
}

export const BellIcon = ({
    color = LISTA_COLORES_ICONOS.BLUE_ACTIVO,
    size = 20,
}: Props) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20">
            <path id="Trazado_55" data-name="Trazado 55" d="M0,0H20V20H0Z" fill="none" />
            <path id="notifications_24dp_FILL1_wght400_GRAD0_opsz24" d="M160-868.1v-1.4h1.4v-4.9a4.1,4.1,0,0,1,.875-2.581,4.038,4.038,0,0,1,2.275-1.479v-.49a1.013,1.013,0,0,1,.306-.744A1.012,1.012,0,0,1,165.6-880a1.012,1.012,0,0,1,.744.306,1.013,1.013,0,0,1,.306.744v.49a4.038,4.038,0,0,1,2.275,1.479,4.1,4.1,0,0,1,.875,2.581v4.9h1.4v1.4Zm5.6,2.1a1.348,1.348,0,0,1-.989-.411,1.348,1.348,0,0,1-.411-.989H167a1.348,1.348,0,0,1-.411.989A1.348,1.348,0,0,1,165.6-866Z" transform="translate(-155.6 883)" fill={color} />
        </svg>
    )
}
