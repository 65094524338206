import { ChevronDownIcon } from 'assets/icons/ChevronDownIcon';
import { ExclamationIcon } from 'assets/icons/ExclamationIcon';
import { LISTA_COLORES_ICONOS } from 'assets/icons/LISTA_COLORES_ICONOS';
import { formatOptions, optionsFormatConfigI, SelectOptionI } from 'components/utils/selectUtils/selectUtils';
import React from 'react';
import ReactSelect, { components, DropdownIndicatorProps, MultiValue, SingleValue } from 'react-select';

interface Props {
    size?: "md" | "lg" | "sm",
    title?: string,
    options?: any[],
    required?: boolean,
    onChange?: any,
    onBlur?: any,
    disabled?: boolean,
    isLoading?: boolean,
    optionsFormatConfig?: optionsFormatConfigI,
    placeholder?: string;
    width?: string;
    height?: string;
    variant?: "gray-container" | "white-container"
    id: string;
    isMulti?: boolean;
    isClearable?: boolean;
    values: any;
    errors?: any;
    touched?: any;
    subtext?: string;
    withSelectAllOption?: boolean;
    selectAllOptionLabel?: string;
    noOptionsMessage?: string;
    emptySelectMessage?: string;
}

// optionsFormatConfig: Ejemplos de uso
// El select trabaja con opciones de la forma {label, value}[]
// Donde muestra el valor del label y envia el valor del value

// Si enviamos options con un formato distinto usamos optionsFormatConfig: Ej, enviamos {id, nombre}
// [{id: 1, nombre:"A"}, id:2, nombre"B"] -> optionsFormatConfig: { label: "nombre", value: "id" },

// Si queremos customizar lo que se muestra en el Select: Ej, enviamos {id, nombre}
// [{id: 1, nombre:"A"}, id:2, nombre"B"] -> optionsFormatConfig: { label: (option) => `{option.nombre} (${option.id})`, value: "id" },
// mostraria: A (1) y B (2)

const BlueDropdownIndicator: React.FC<DropdownIndicatorProps<any, false>> = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ChevronDownIcon />
        </components.DropdownIndicator>
    );
};

const BlackDropdownIndicator: React.FC<DropdownIndicatorProps<any, false>> = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg stroke="#38485C" fill="#38485C" stroke-width="0" viewBox="0 0 24 24" height="0.7em" width="0.7em" xmlns="http://www.w3.org/2000/svg"><path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"></path></svg>        </components.DropdownIndicator>
    );
};

export const Select = ({
    size = "md",
    title,
    options,
    required,
    onChange = () => { },
    disabled,
    isLoading,
    optionsFormatConfig,
    placeholder,
    width,
    height,
    id,
    variant = "white-container",
    isMulti,
    isClearable,
    values,
    onBlur,
    errors,
    touched,
    subtext,
    noOptionsMessage,
    withSelectAllOption,
    selectAllOptionLabel,
    emptySelectMessage,
}: Props) => {

    const handleChange = (newValue: SingleValue<any | SelectOptionI> | MultiValue<any | SelectOptionI>, selectedOption: any) => {
        let value
        if (isMulti) {
            if (selectedOption?.option?.value == "TODOS") {
                const options = formatSelectOptions()
                value = options.map((ctV) => ctV.value)
            } else {
                value = (newValue as SelectOptionI[])?.map((ctV) => ctV.value)
            }
        } else {
            value = (newValue as SelectOptionI).value
        }
        onChange({ target: { value, id } })
    }

    const handleBlur = () => {
        const event = { target: { id, value: id } }
        if (onBlur) onBlur(event)
    }

    const formatSelectOptions = () => {
        const formatedOptions = optionsFormatConfig ?
            formatOptions({ options, optionsFormatConfig })
            :
            options as SelectOptionI[]

        return formatedOptions
    }

    const getOptions = () => {
        const options = formatSelectOptions()
        if (withSelectAllOption) {
            console.log({ values, id })
            const wasAllSelected = options.every(ctOp => values[id]?.some((ctSelectedId: string) => ctSelectedId == ctOp.value))
            if (!wasAllSelected) {

                const selectAppOption = { value: "TODOS", label: selectAllOptionLabel ? selectAllOptionLabel : "Todos", original: "" }
                options.push(selectAppOption)
            }
        }
        return options
    }

    const selectOptions: SelectOptionI[] = formatSelectOptions()

    const showError = touched && touched[id] && errors && errors[id]

    const getComponentValue = (selectOptions: SelectOptionI[]) => {
        let componentValue: SelectOptionI | SelectOptionI[] | undefined;
        if (isMulti) {
            componentValue = selectOptions.filter(ctOp => values[id]?.some((ctV: number | string) => ctV == ctOp.value))
        } else {
            componentValue = selectOptions.find(ctOp => ctOp.value == values[id])
        }

        return componentValue ?? null
    }

    return (
        <label className={`select-label ${variant ? variant : ""} ${size ? size : ""} ${showError ? "error" : ""}`} style={{ width }}>
            {title && <h5 className="label">{title} {required && "*"}</h5>}
            <ReactSelect
                styles={{ control: (provided) => ({ ...provided, width, height }) }}
                // components={{ DropdownIndicator: variant == "white-container" ? BlueDropdownIndicator : BlackDropdownIndicator }}
                placeholder={placeholder}
                classNamePrefix={`custom-select`}
                options={getOptions()}
                isLoading={isLoading}
                isMulti={isMulti}
                isClearable={isClearable}
                onChange={handleChange}
                value={(selectOptions && values) ? getComponentValue(selectOptions) : null}
                isSearchable={false}
                // value={null}
                isDisabled={disabled}
                onBlur={handleBlur}
                // noOptionsMessage={(params: any) => {
                //     return (!params.inputValue || params.inputValue == "") ?
                //         (emptySelectMessage ?? "Ingresar un valor")
                //         :
                //         (noOptionsMessage ?? "No hay opciones")
                // }}
            />
            {subtext && <p className="small">{subtext}</p>}
            {showError && <div className="d-flex align-items-center gap-1 mt-1">
                <ExclamationIcon size={14} color={LISTA_COLORES_ICONOS.ERROR} />
                <p className="component-error-msg mb-0">
                    {errors[id]}
                </p>
            </div>}
        </label>
    )
}
