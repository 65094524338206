import { EstadoNotificacionI } from "interfaces/EstadoNotificacion";
import { EstadoNovedadI } from "interfaces/EstadoNovedad";

export interface infoFetchGetEstadoNovedadI {
    id: number | string;
    name: string;
    description: string;
    borderColor: string;
    backgroundColor: string;
    color: string;
}

class EstadoNovedad implements EstadoNovedadI {
    id: number | string;
    name: string;
    description: string;
    borderColor: string;
    backgroundColor: string;
    color: string;

    constructor(infoFetch: infoFetchGetEstadoNovedadI) {
        this.id = infoFetch.id;
        this.name = infoFetch.name;
        this.description = infoFetch.description;
        this.borderColor = infoFetch.borderColor;
        this.backgroundColor = infoFetch.backgroundColor;
        this.color = infoFetch.color;
    }
}

export const makeEstadoNovedad = (infoFetch: infoFetchGetEstadoNovedadI) => {
    return new EstadoNovedad(infoFetch)
}