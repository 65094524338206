import { AreaI } from "interfaces/Area";

export interface infoFetchGetAreaI {
    id: number | string;
    name: string;
    description: string;
}

class Area implements AreaI {
    id: number | string;
    name: string;
    description: string;

    constructor(infoFetch: infoFetchGetAreaI) {
        this.id = infoFetch.id;
        this.name = infoFetch.name;
        this.description = infoFetch.description;
    }
}

export const makeArea = (infoFetch: infoFetchGetAreaI) => {
    return new Area(infoFetch)
}