import { AnioNivelI } from "interfaces/AnioNivel";

export interface infoFetchGetAnioNivelI {
    id: number | string;
    name: string;
    description: string;
}

class AnioNivel implements AnioNivelI {
    id: number | string;
    name: string;
    description: string;

    constructor(infoFetch: infoFetchGetAnioNivelI) {
        this.id = infoFetch.id;
        this.name = infoFetch.name;
        this.description = infoFetch.description;
    }
}

export const makeAnioNivel = (infoFetch: infoFetchGetAnioNivelI) => {
    return new AnioNivel(infoFetch)
}