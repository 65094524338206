import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { axiosInstance } from './request.service';
import Cookies from 'js-cookie';
import { COOKIES_NAME } from 'constants/appConstants';
import { AuthTokens, refresh } from 'services/auth';
import { servicesUtils } from '../utils/services.utils'
import { store } from '../../store/store'
import { logout } from '../../store/slices/authSlice'

export interface BackendErrores { [key: string]: { msg: string } }

export interface BackendError {
  errores: BackendErrores;
  message: string;
  status: number;
}

const defaultBackendError: BackendError = {
  errores: {},
  message: "Error inesperado",
  status: 500,
}

let isRefreshing = false;
let failedQueue: any[] = [];
const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });

  failedQueue = [];
};


export const AxiosInterceptor = () => {
  const updateHeader = (request: any) => {
    const rawTokens = Cookies.get(COOKIES_NAME);
    if (rawTokens) {
      const tokens: AuthTokens = JSON.parse(rawTokens);
      const newHeaders = {
        "Authorization": `Bearer ${tokens.accessToken}`,
        "withCredentials": "true",
      }
      request.headers = request.headers ? { ...request.headers, ...newHeaders } : newHeaders;
    }
    return request
  }

  const errorHandler = async (error: AxiosError<BackendError>) => {
    const errorMessage = error.response ? error.response.data.message : defaultBackendError.message
    const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean };

    if(error.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
        .then((token) => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return axiosInstance(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
      }
      // recuperar refresh token de cookies
      const rawTokens = Cookies.get(COOKIES_NAME);
      const tokens: AuthTokens = JSON.parse(rawTokens || '');
      const isValid = servicesUtils.isTokenValid(tokens.refreshToken)
      if(!isValid) {
        store.dispatch(logout())
        return
      }
      try {
        const {accessToken, refreshToken} = await refresh(tokens.refreshToken)
        Cookies.set(COOKIES_NAME, JSON.stringify({accessToken, refreshToken}))
        processQueue(null, accessToken);
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        console.log(err)
      } finally {
        isRefreshing = false;
      }
    } else {
      const errorResponse = error.response ? error.response.data : defaultBackendError
      return Promise.reject<BackendError>(errorResponse)
    }    
  }

  const responseHandler = (response: any) => {
    return response.data
  }

  //Request salientes
  axiosInstance.interceptors.request.use((request: any) => updateHeader(request));

  //Respone Entrante
  axiosInstance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  );
}
