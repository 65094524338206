import React from 'react'

export const ICON_COLORS = {
    blue: "#3786EE",
    lightGray: "#D1D8E0",
    white: "#FFFFFF",
    // gray: "#8B8B8B",
    gray: "#D1D8E0",
}

interface PropsI {
    size?: string;
    variant?: keyof typeof ICON_COLORS;
    color?: string;
}

export const CheckIcon = ({
    size = "35px",
    variant = "gray",
    color,
}: PropsI) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 80 80">
            <circle className="st0" cx="40" cy="40" r="37.3" fill={color ?? ICON_COLORS[variant]} />
            <polyline className="st1" points="21.9,43 32.6,53.8 56.9,29.6 "
                fill="none"
                stroke={"white"}
                strokeWidth="7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
            />
        </svg>
    )
}

