import { LISTA_COLORES_ICONOS } from './LISTA_COLORES_ICONOS';

interface Props {
    color?: (typeof LISTA_COLORES_ICONOS)[keyof typeof LISTA_COLORES_ICONOS];
    size?: number;
}

export const NewUserIcon = ({
    color = LISTA_COLORES_ICONOS.BLUE_ACTIVO,
    size = 20,
}: Props) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20">
            <g id="Grupo_12" data-name="Grupo 12">
                <path id="Trazado_88" data-name="Trazado 88" d="M0,0H20V20H0Z" fill="none" />
            </g>
            <path id="person_add_FILL1_wght400_GRAD0_opsz24" d="M50.818-793.636v-1.909H48.909v-1.273h1.909v-1.909h1.273v1.909H54v1.273H52.091v1.909Zm-5.727-1.273a2.451,2.451,0,0,1-1.8-.748,2.451,2.451,0,0,1-.748-1.8,2.451,2.451,0,0,1,.748-1.8,2.451,2.451,0,0,1,1.8-.748,2.451,2.451,0,0,1,1.8.748,2.451,2.451,0,0,1,.748,1.8,2.451,2.451,0,0,1-.748,1.8A2.451,2.451,0,0,1,45.091-794.909ZM40-789.818V-791.6a1.864,1.864,0,0,1,.278-.994,1.856,1.856,0,0,1,.74-.692,9.446,9.446,0,0,1,2-.74,8.75,8.75,0,0,1,2.068-.247,8.751,8.751,0,0,1,2.068.247,9.446,9.446,0,0,1,2,.74,1.856,1.856,0,0,1,.74.692,1.864,1.864,0,0,1,.278.994v1.782Z" transform="translate(-37 804.909)" fill={color} />
        </svg>
    )
}
