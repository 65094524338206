import React from 'react'
import { ICON_COLORS } from './CheckIcon'

const ARROW_DIRECTION = {
    right: {},
    left: { transform: "rotateZ(180deg)" },
    down: { transform: "rotateZ(90deg)" },
    up: { transform: "rotateZ(270deg)" },
}

interface Props {
    size?: number;
    direction?: keyof typeof ARROW_DIRECTION;
    color?: keyof typeof ICON_COLORS;
    style?: { [key: string]: string };
    className?: string;
}

export const ChevronIcon = ({
    size = 13,
    direction = "left",
    color = "gray",
    style = {},
    className,
}: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={ICON_COLORS[color]} viewBox="0 0 16 16"
            style={{ ...ARROW_DIRECTION[direction], ...style }}
            className={className}
        >
            <path
                fillRule="evenodd"
                stroke={ICON_COLORS[color]}
                strokeWidth={1.5}
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
        </svg >
    )
}

