import { infoFetchGetSchoolExI, makeEscuela } from "dtos/escuela"
import { ENDPOINTS } from "./endpoints"
import { fetchingByIdPropsI, fetchingPropsI } from "./request/request.interface"
import { sendRequest } from "./request/request.service"
import { servicesUtils } from "./utils/services.utils"

export const getEscuelasByCue = async ({ id, params, signal }: fetchingByIdPropsI) => {
    const response = await sendRequest<infoFetchGetSchoolExI[]>({
        method: "GET",
        uri: ENDPOINTS.SCHOOL_EX + "/" + id,
        params: servicesUtils.removeNullParamsInFilters(params),
        signal,
    })

    return response.map(ctEl => makeEscuela(ctEl))
}