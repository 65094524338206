import BPagination from 'react-bootstrap/Pagination';
import "./pagination.css";
import { Select } from 'components/Select/Select';
import { ChevronDownIcon } from 'assets/icons/ChevronDownIcon';
import { ChevronPageIcon } from 'assets/icons/ChevronPageIcon';

interface Props {
    totalItems: any;
    currentPage: any;
    onChangePage: (newFiltros: { [key: string]: (string | number) }) => void;
    currentLimit?: number;
    className?: string;
    style?: { [key: string]: string }
    showPaginationOnlyOnMoreThanPages?: number;
}

export const Pagination = ({
    totalItems,
    currentPage,
    currentLimit = 10,
    onChangePage,
    className,
    style = {},
    showPaginationOnlyOnMoreThanPages = 0,
}: Props) => {

    const ultimaPagina = Math.ceil(totalItems / currentLimit)

    return (
        <div className="d-flex justify-content-between">
            {
                ultimaPagina > showPaginationOnlyOnMoreThanPages ?
                    <BPagination>
                        {currentPage > 1 && <BPagination.Item onClick={() => currentPage > 1 && onChangePage({ page: currentPage - 1, limit: currentLimit })}>Anterior</BPagination.Item>}
                        {currentPage > 2 && <BPagination.Item onClick={() => currentPage > 1 && onChangePage({ page: 1, limit: currentLimit })}>{1}</BPagination.Item>}
                        {currentPage >= 4 && <BPagination.Ellipsis />}

                        {currentPage > 1 && <BPagination.Item onClick={() => onChangePage({ page: currentPage - 1, limit: currentLimit })}>{currentPage - 1}</BPagination.Item>}
                        <BPagination.Item active >{currentPage} </BPagination.Item>
                        {currentPage < ultimaPagina && <BPagination.Item onClick={() => onChangePage({ page: currentPage + 1, limit: currentLimit })}>{currentPage + 1}</BPagination.Item>}

                        {currentPage < ultimaPagina - 2 && <BPagination.Ellipsis />}
                        {currentPage < ultimaPagina - 1 && <BPagination.Item onClick={() => onChangePage({ page: ultimaPagina, limit: currentLimit })} >{ultimaPagina}</BPagination.Item>}
                        {currentPage < ultimaPagina && <BPagination.Item onClick={() => currentPage < ultimaPagina && onChangePage({ page: currentPage + 1, limit: currentLimit })}> 
                        Siguiente
                        <ChevronPageIcon/>
                        </BPagination.Item>}
                    </BPagination>
                    :
                    <div></div>
            }

            <div className="d-flex aligh-items-center gap-2">
                <small className="text-nowrap pt-2">
                    Cantidad de resultados
                </small>
                <Select
                    id="limit"
                    width={"90px"}
                    values={{ limit: currentLimit }}
                    options={[{ label: 5, value: 5 }, { label: 10, value: 10 }, { label: 20, value: 20 }]}
                    onChange={(e: any) => onChangePage({ page: 1, limit: e.target.value })}
                />
            </div>
        </div>
    )
}
