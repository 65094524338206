import React from 'react'

interface Props {
    size?: number;
    className?: string;
}
export const ChevronHeaderIcon = ({
    size = 34,
    className,
}: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} className={className} viewBox="0 0 24 24" fill="none" stroke="#526071" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>
    )
}
