import { toast } from 'react-toastify';
import "./../../styles/components/alerts.css";
import { SuccessIcon } from 'assets/icons/SuccessIcon';
import { ErrorIcon } from 'assets/icons/ErrorIcon';
export const showSuccessToast = (boldText: string, message?: string) => {
    toast.success(
        <div>
            <b>{boldText}</b> {message && <span>{message}</span>}
        </div>, 
        {
            className: 'custom-toast-success',
            closeButton: false,
            icon: <SuccessIcon/>,
        }
    );
};
export const showErrorToast = (boldText: string, message?: string) => {
    toast.error(
        <div>
            <b>{boldText}</b> {message && <span>{message}</span>}
        </div>,
        {
            className: 'custom-toast-error',
            closeButton: false,
            icon: <ErrorIcon />,
        }
    );
};
export const CustomToast = () => {
    return null;
};
