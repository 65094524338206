import { format, isEqual, parse, parseISO, addMinutes } from "date-fns"

const stringTime = (date: Date) => {
    return format(date, "HH:mm")
}

const stringDate = (date: Date) => {
    return format(date, "dd/MM/yyyy")
}

const stringDateTime = (date: Date) => {
    return format(date, "dd/MM/yyyy - HH:mm") + "h"
}

const formTime = (date: Date) => {
    return format(date, "HH:mm");
}

const formDate = (date: Date) => {
    return format(date, "yyyy-MM-dd")
}

const formDateToStringDate = (YYYY_MM_DD: string) => {
    const date = parseISO(YYYY_MM_DD)
    return stringDate(date)
}

const formDateEsHoy = (YYYY_MM_DD: string) => {
    const hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    const fecha = parse(YYYY_MM_DD, 'yyyy-MM-dd', new Date());
    return isEqual(hoy, fecha);
}


const redondearHoraActual = () => {
    const ahora = new Date();
    const minutos = ahora.getMinutes();

    if (minutos !== 0) {
        ahora.setMinutes(0); 
        ahora.setHours(ahora.getHours() + 1); 
    }
    return stringTime(ahora); 
}

export const dateFunctions = {
    redondearHoraActual,
}

export const formatDate = {
    stringTime,
    stringDate,
    stringDateTime,

    formTime,
    formDate,
    formDateToStringDate,
    formDateEsHoy,
}