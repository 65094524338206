import { PERMISOS } from "constants/permissions";
import { PRIVATE_ROUTE } from "../../../../routes/privateRoutes";
import { Permisos } from "types/Permisos";

interface BreadcrumbPathI {
    label: string;
    path?: string;
    permissions?: Permisos;
}

export const BREADCRUMB_OPTIONS: { [key: string]: BreadcrumbPathI[] } = {
    [PRIVATE_ROUTE.NOTIFICACIONES]: [
        { label: "Notificaciones" }
    ],
    [PRIVATE_ROUTE.USUARIOS]: [
        { label: "Usuarios",
          permissions: [PERMISOS.VIEW_USER]
         }
    ],
    [PRIVATE_ROUTE.NOVEDADES]: [
        { label: "Novedades" }
    ],
    [PRIVATE_ROUTE.INICIO]: [
        // { label: "Docentes", path: PRIVATE_ROUTE.DOCENTES },
        { label: "Inicio" },
    ],
    [PRIVATE_ROUTE.PERFIL_USUARIOS]: [
        { label: "Perfil de usuario" }
    ],

    [PRIVATE_ROUTE.NOTIFICACIONES_CREAR]: [
        { label: "Notificaciones", path: PRIVATE_ROUTE.NOTIFICACIONES },
        { label: "Nueva notificación" },
    ],


}

const adaptPathname = (pathName: string) => {
    const splited = pathName.split("/")

    const mapped = splited.map(ctString => (
        !ctString ?
            "" :
            ctString.includes(":") ?
                "n"
                :
                ctString
    ))

    const joined = mapped.join("/")
    return joined
}


const converPathParamIntoN = (pathName: string) => {
    const adaptedPathname = pathName
        .split("/")
        .map(ctString => (
            !ctString ?
                "" :
                isNaN(Number(ctString)) ? ctString : "n")
        )
        .join("/")
    return adaptedPathname
}

export const getBreadcrumbOptions = (pathName: string) => {
    const convetedPath = converPathParamIntoN(pathName)
    const adaptedOptions: { [key: string]: BreadcrumbPathI[] } = {}
    Object.keys(BREADCRUMB_OPTIONS).forEach(ctKey => adaptedOptions[adaptPathname(ctKey)] = BREADCRUMB_OPTIONS[ctKey])
    return adaptedOptions[convetedPath]
}