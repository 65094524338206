import { Table } from 'react-bootstrap';
import "./usuariosTable.css";
import { UsuariosRow } from './UsuariosRow';
import { UsuarioI } from 'interfaces/Usuario';

interface Props {
    elementos: UsuarioI[],
    handleDelete: (id: number | string) => void,
    refreshPage: () => void,
}

export const UsuariosTable = ({
    elementos = [],
    handleDelete,
    refreshPage,
}: Props) => {

    return (
        <>
            <Table striped bordered className='table-container'>
                <thead>
                    <tr className='table-heading'>
                        <th>Nombre</th>
                        <th>Mail</th>
                        <th>Área</th>
                        <th>Rol</th>
                        <th>Alta de rol</th>
                        <th className='text-center'>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {elementos.length > 0 ? (
                        elementos.map(ctEl =>
                            <UsuariosRow
                                key={ctEl.id}
                                usuario={ctEl}
                                handleDelete={handleDelete}
                                searchUsuarios={refreshPage}
                            />
                        )
                    ) : (
                        <tr>
                            <td colSpan={6} className="text-center table-error-message">
                                <p><b>No hay usuarios para visualizar.</b> Para poder visualizar usuarios debes <br/> seleccionar el botón agregar «Nuevo usuario».</p>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    );
};
