import { AreaI } from "interfaces/Area";
import { RolI } from "interfaces/Rol";
import { UsuarioI } from "interfaces/Usuario";

export interface infoFetchGetUsuariosI {
    active: boolean;
    area: AreaI | null;
    createdAt: string;
    email:string;
    id: number | string;
    lastname: string
    name: string;
    role: RolI;
    pictureUrl: String;
}

class Usuario implements UsuarioI {
        active: boolean;
        area: AreaI | null;
        createdAt: string;
        email:string;
        id: number | string;
        lastname: string
        name: string;
        role: RolI;
        pictureUrl: String;

    constructor(infoFetch: infoFetchGetUsuariosI) {
        this.active = infoFetch.active;
        this.area = infoFetch.area;
        this.createdAt = infoFetch.createdAt;
        this.email = infoFetch.email;
        this.id = infoFetch.id;
        this.lastname = infoFetch.lastname;
        this.name = infoFetch.name;
        this.role = infoFetch.role;
        this.pictureUrl = infoFetch.pictureUrl; 
    }
}

export const makeUsuario = (infoFetch: infoFetchGetUsuariosI) => {
    return new Usuario(infoFetch)
}