import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { Permisos } from "types/Permisos";

/**
 * @param requiredPermissions Array de los permisos requeridos
 * @returns Boolean
 */
const useHasPermission = (requiredPermissions: Permisos = [] ) => {
  const auth = useSelector((state: RootState) => state.authData);

  if ( requiredPermissions.length === 0
    ||  !auth.isLogged 
    ||  !auth.permissions 
    ||  auth.permissions.length === 0
  ) {
    return false;
  }
  return requiredPermissions.every((p) => auth.permissions?.includes(p));
};

export default useHasPermission;
