import { createContext, ReactNode, useState } from "react";
import LoadingOverlay from "../components/utils/LoadingOverlay";

export interface UtilsContextType {
  showLoadingOverlay: boolean;
  setShowLoadingOverlay: (show: boolean) => void;
}

const UtilsContext = createContext<UtilsContextType>({
  showLoadingOverlay: false,
  setShowLoadingOverlay: () => {},
});

interface UtilsProviderProps {
  children: ReactNode;
}

export const UtilsProvider = ({ children }: UtilsProviderProps) => {
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  return (
    <UtilsContext.Provider value={{ showLoadingOverlay, setShowLoadingOverlay }}>
      {showLoadingOverlay && <LoadingOverlay />}
      {children}
    </UtilsContext.Provider>
  );
};

export default UtilsContext;
