import { info } from "console";
import { format } from "date-fns";
import { AccionesNotificacionI } from "interfaces/AccionesNotificacion";
import { AnioNivelI } from "interfaces/AnioNivel";
import { AreaI } from "interfaces/Area";
import { EscuelaI } from "interfaces/Escuela";
import { EstadoNotificacionI } from "interfaces/EstadoNotificacion";
import { EstadoNovedadI } from "interfaces/EstadoNovedad";
import { NivelEscuelaI } from "interfaces/NivelEscuela";
import { NotificacionI } from "interfaces/Notificacion";
import { NovedadI } from "interfaces/Novedad";
import { TipoNotificacionI } from "interfaces/TipoNotificacion";
import { MinUsuarioI } from "interfaces/Usuario";

export interface infoFetchGetNovedadI {
    id: number | string;
    title: string;
    subtitle: string;
    description?: string;
    link?: string;
    area: AreaI | null;
    newState: EstadoNovedadI;
    schoolLevels: NivelEscuelaI[]; 
    createdAt: Date;
    sendAt: Date | null;
    deletedAt: Date | null;
    isProgrammed: boolean;
    isEditable: boolean;
}

class Novedad implements NovedadI {
    id: number | string;
    title: string;
    subtitle: string;
    description?: string;
    link?: string;
    area: AreaI | null;
    newState: EstadoNovedadI;
    schoolLevels: NivelEscuelaI[];
    createdAt: Date;
    sendAt: Date | null;
    deletedAt: Date | null;
    isProgrammed: boolean;
    isEditable: boolean;

    constructor(infoFetch: infoFetchGetNovedadI) {
        this.id = infoFetch.id;
        this.title = infoFetch.title;
        this.subtitle = infoFetch.subtitle;
        this.description = infoFetch.description;
        this.link = infoFetch.link;
        this.newState = infoFetch.newState;
        this.area = infoFetch.area;
        this.schoolLevels = infoFetch.schoolLevels;
        this.createdAt = new Date(infoFetch.createdAt);
        this.sendAt = infoFetch.sendAt ? new Date(infoFetch.sendAt) : null;
        this.deletedAt = infoFetch.deletedAt ? new Date(infoFetch.deletedAt) : null;
        this.isProgrammed = infoFetch.isProgrammed;
        this.isEditable = infoFetch.isEditable;
    }
}

export const makeNovedad = (infoFetch: infoFetchGetNovedadI) => {
    return new Novedad(infoFetch)
}