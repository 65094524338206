import React from "react";

const LoadingOverlay = () => {
  return (
    <div className="loader-container-overlay">
      <div className="bg-spinner">
        <div className="container mt-5 pt-5">
          <div className="row mt-5 pt-5">
            <div className="col-12 text-center">
              <div className="flex-container">
                <div className="rainbow"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
