import { TipoNotificacionI } from "interfaces/TipoNotificacion"

export interface infoFetchGetTiposNotificacionI {
    id: number | string;
    name: string;
    description: string;
}

class TipoNotificacion implements TipoNotificacionI {
    id: number | string;
    name: string;
    description: string;

    constructor(infoFetch: infoFetchGetTiposNotificacionI) {
        this.id = infoFetch.id;
        this.name = infoFetch.name;
        this.description = infoFetch.description;
    }
}

export const makeTipoNotificacion = (infoFetch: infoFetchGetTiposNotificacionI) => {
    return new TipoNotificacion(infoFetch)
}