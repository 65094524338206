import { LISTA_COLORES_ICONOS } from './LISTA_COLORES_ICONOS';

interface Props {
    color?: (typeof LISTA_COLORES_ICONOS)[keyof typeof LISTA_COLORES_ICONOS];
    size?: number;
}

export const LupaIcon = ({
    color = LISTA_COLORES_ICONOS.BLUE_ACTIVO,
    size = 18,
}: Props) => {
    return (
        // <svg width={size} height={size} viewBox="0 0 18 18.004">
        //     <path id="Icon_ionic-ios-search" data-name="Icon ionic-ios-search" d="M22.289,21.194l-5.006-5.053a7.134,7.134,0,1,0-1.083,1.1l4.973,5.02a.77.77,0,0,0,1.087.028A.775.775,0,0,0,22.289,21.194ZM11.676,17.3a5.633,5.633,0,1,1,3.984-1.65A5.6,5.6,0,0,1,11.676,17.3Z" transform="translate(-4.5 -4.493)" fill={color} />
        // </svg>

        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} className="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
        </svg>

    )
}
