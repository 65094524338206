import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./breadcrumb.css";
import { DashBreadcrumb } from "./components/DashBreadcrumb/DashBreadcrumb";
import { DashNav } from "./components/DashNav/DashNav";
import "./dashboard.css";

export const Dashboard = () => {
  return (
    <div className="wrapper d-flex align-items-stretch flex-grow-1">
      {/* <ToastContainer position="bottom-right" /> */}
      <DashNav />
      <main
        role="main"
        id="content"
        className="dash-content-container"
      >
        <DashBreadcrumb />
        <Suspense fallback={<></>} >
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
};