import { AsyncSelect } from 'components/AsyncInput/AsyncSelect';
import { showErrorToast, showSuccessToast } from 'components/CustomAlerts/CustomToast';
import { Input } from 'components/Input/Input';
import { Radio } from 'components/Radio/Radio';
import { Select } from 'components/Select/Select';
import { useFormik } from 'formik';
import { AnioNivelI } from 'interfaces/AnioNivel';
import { EscuelaI } from 'interfaces/Escuela';
import { MinUsuarioI } from 'interfaces/Usuario';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'routes/privateRoutes';
import { createNotificacionSchema } from 'schemas/notificaciones/createNotificacionSchema';
import { getAniosEscuelaByNivel } from 'services/anios';
import { getEscuelasByCue } from 'services/escuelas';
import { getNotificacionById, postNotificacion, putNotificacion } from 'services/notificaciones';
import { getUsuarios } from 'services/usuarios';
import { fetchEstadosNotificacion, fetchNivelesEscuela, fetchTiposNotificacion } from 'store/slices/appSlice';
import { AppDispatch, RootState } from 'store/store';
import { dateFunctions, formatDate } from '../../../utils/formatUtils';
import { format } from 'date-fns';

interface Props {
    typeOfAction?: abmActionI;
}

export type abmActionI = "create" | "edit"
export enum tipoNotificacionId {
    GENERAL = 1,
    SEGMENTADA = 2,
}

interface defaultOptionsSelected {
    default_selected_escuelas: EscuelaI[] | null,
    default_selected_correos_usuarios: MinUsuarioI[] | null,
}

export const NotificacionesABM = ({
    typeOfAction = "create",
}: Props) => {
    let { id } = useParams();

    const dispatch: AppDispatch = useDispatch();
    const { tiposNotificacion, nivelesEscuela, estadosNotificacion, loadingAppData } = useSelector((st: RootState) => st.appData)
    const [gradosNivelOptions, setGradosNivelOptions] = useState<AnioNivelI[] | null>(null)
    const [defaultOptionsSelected, setDefaultOptionsSelected] = useState<defaultOptionsSelected>({
        default_selected_escuelas: null,
        default_selected_correos_usuarios: null,
    });

    const [isLoading, setIsLoading] = useState({
        gradosNivel: false,
        form: false,
    })

    const handleReturn = () => {
        navigate(PRIVATE_ROUTE.NOTIFICACIONES)
    }

    const handleSubmitCreate = async (values: { [key: string]: any }, { setSubmitting }: any) => {
        try {
            const params = { ...values }
            const arr_escuelas_cue = params.arr_escuelas_cue.map((ctCue: string) => Number(ctCue))

            /* TODO: Agrego adapter hasta que el backend trabaje con params con_notificar_envio */
            if (params.con_notificar_envio === false) {
                delete params.arr_escuelas_cue;
            }
            delete params.con_notificar_envio
            /*  */

            await postNotificacion({ ...params, arr_escuelas_cue })
            handleReturn()
            showSuccessToast("¡La notificación fue creada con éxito!",
                values.isProgrammed ?
                    `Tu notificación se enviará el ${formatDate.formDateToStringDate(values.fecha)} a las ${values.hora} horas.`
                    :
                    undefined
            );
        }
        catch (err: any) {
            showErrorToast("La notificación no pudo ser enviada.", err.message)
        }
        finally {
            setSubmitting(false);
        }
    }

    const handleSubmitEdit = async (values: { [key: string]: any }, { setSubmitting }: any) => {
        try {
            const params = { ...values }
            const arr_escuelas_cue = params.arr_escuelas_cue.map((ctCue: string) => Number(ctCue))

            /* TODO: Agrego adapter hasta que el backend trabaje con params con_notificar_envio */
            if (params.con_notificar_envio === false) {
                delete params.arr_escuelas_cue;
            }
            delete params.con_notificar_envio
            /*  */

            await putNotificacion({ ...params, arr_escuelas_cue })
            handleReturn()
            showSuccessToast("¡La notificación fue editada y reprogramada con éxito!",
                values.isProgrammed ?
                    `Tu notificación se enviará el ${formatDate.formDateToStringDate(values.fecha)} a las ${values.hora} horas.`
                    :
                    undefined
            )
        }
        catch (err: any) {
            showErrorToast("La notificación no pudo ser enviada.", " Por favor, intenta nuevamente en unos minutos.")
        }
        finally {
            setSubmitting(false);
        }
    }

    const navigate = useNavigate();

    interface notificacionABMValuesI {
        id?: string | number,
        notificacion_titulo: string,
        notificacion_msg: string;
        tipo_notificacion_id: number | string;
        nivel_id: number | string;
        arr_grados_id: (number | string)[];
        arr_escuelas_cue: (number | string)[];
        arr_correos_usuarios: (string)[];
        isProgrammed: boolean;
        con_notificar_envio: boolean;
        fecha: string;
        hora: string;
        default_selected_escuelas?: EscuelaI[];
    }

    const defaultInitialValues: notificacionABMValuesI = {
        tipo_notificacion_id: "",
        nivel_id: "",
        arr_grados_id: [],
        arr_escuelas_cue: [],
        arr_correos_usuarios: [],
        isProgrammed: false,
        con_notificar_envio: false,
        fecha: "",
        hora: "",
        notificacion_titulo: "",
        notificacion_msg: "",
    }

    const { values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
    } = useFormik({
        initialValues: defaultInitialValues,
        validationSchema: createNotificacionSchema,
        onSubmit: typeOfAction == "create" ? handleSubmitCreate : handleSubmitEdit,
    });



    const loadGradosNivelOptions = async (nivelId: number | string) => {
        try {
            setGradosNivelOptions(null)
            setIsLoading(st => ({ ...st, gradosNivel: true }))
            const elementos = await getAniosEscuelaByNivel({ id: nivelId })
            setGradosNivelOptions(elementos)
        } catch (err) {
            showErrorToast("No fue posible cargar los grados del nivel seleccionado")
        } finally {
            setIsLoading(st => ({ ...st, gradosNivel: false }))
        }
    }

    const handleChangeNivel = (e: any) => {
        const nuevoNivelId = e.target.value;
        loadGradosNivelOptions(nuevoNivelId);
        handleChange(e);
    
        setValues(prevValues => ({
            ...prevValues,
            nivel_id: nuevoNivelId,
            arr_grados_id: [], 
            arr_escuelas_cue: [],
        }));
    
        setDefaultOptionsSelected(prev => ({
            ...prev,
            default_selected_escuelas: [],
        }));
    };

    const loadEditForm = async () => {
        try {
            setIsLoading(st => ({ ...st, form: true }))
            const errorLoadingNotificacionResolve = () => {
                showErrorToast("No fue posible cargar la notificacion a editar")
                navigate(PRIVATE_ROUTE.NOTIFICACIONES)
            }

            if (!id) return errorLoadingNotificacionResolve()

            const notificacion = await getNotificacionById(id)

            if (!notificacion) return errorLoadingNotificacionResolve()

            const initialValues = {
                id: notificacion.id,
                notificacion_titulo: notificacion.title,
                notificacion_msg: notificacion.body,
                tipo_notificacion_id: notificacion.notificationType?.id,
                nivel_id: notificacion.schoolLevel?.id,
                arr_grados_id: notificacion.schoolYears.map(ctEl => ctEl.id),
                arr_escuelas_cue: notificacion.schools.map(ctEl => ctEl.CUE),
                arr_correos_usuarios: notificacion.usersSend.map(ctEl => ctEl.email),
                isProgrammed: notificacion.isProgrammed,
                con_notificar_envio: notificacion.usersSend.length > 0,
                fecha: formatDate.formDate(notificacion.sendAt),
                hora: formatDate.formTime(notificacion.sendAt),
            }

            setDefaultOptionsSelected({
                default_selected_escuelas: notificacion.schools,
                default_selected_correos_usuarios: notificacion.usersSend,
            })
            if (notificacion.schoolLevel?.id) loadGradosNivelOptions(notificacion.schoolLevel.id)
            setValues(initialValues)
        } catch (err) {
            console.log("No fue posible cargar los grados del nivel seleccionado");
        } finally {
            setIsLoading(st => ({ ...st, form: false }))
        }
    }

    const getEscuelas = async (params: { [any: string]: string }) => {
        const options = await getEscuelasByCue({
            id: params.id,
            params: { nivel_id: values.nivel_id },
        })
        return options
    }

    useEffect(() => {
        if (typeOfAction == "edit") loadEditForm()

        if (!tiposNotificacion) dispatch(fetchTiposNotificacion());
        if (!estadosNotificacion) dispatch(fetchEstadosNotificacion());
        if (!nivelesEscuela) dispatch(fetchNivelesEscuela());
    }, [])

    useEffect(() => {
        console.log({ values })
    }, [values])
    return (
        <section>
            <h1 className="section-title">
                {
                    typeOfAction == "edit" ?
                        "Editar notificación"
                        :
                        "Nueva notificación"
                }</h1>

            <h2 className="section-subtitle mt-2">Enviá o programá una nueva notificación.</h2>

            <Card>
                <form onSubmit={handleSubmit}>
                    <Row className="">
                        <Col sm={4} className="mb-3">
                            <Select
                                id="tipo_notificacion_id"
                                title="Tipo de notificación"
                                size="lg"
                                optionsFormatConfig={{ value: "id", label: "name" }}
                                options={tiposNotificacion ?? []}
                                isLoading={loadingAppData.tiposNotificacion}
                                placeholder="Seleccionar"
                                variant="gray-container"
                                required
                                values={values}
                                errors={errors}
                                touched={touched}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Col>
                        {values.tipo_notificacion_id == tipoNotificacionId.SEGMENTADA && <>
                            <Col sm={4} className="mb-3">
                                <Select
                                    id="nivel_id"
                                    title="Nivel"
                                    size="lg"
                                    optionsFormatConfig={{ value: "id", label: "name" }}
                                    options={nivelesEscuela ?? []}
                                    isLoading={loadingAppData.nivelesEscuela || isLoading.gradosNivel}
                                    disabled={isLoading.gradosNivel}
                                    placeholder="Seleccionar"
                                    variant="gray-container"
                                    required
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    onChange={handleChangeNivel}
                                    onBlur={handleBlur}

                                />
                            </Col>
                            <Col sm={4} className="mb-3">
                                <Select
                                    id="arr_grados_id"
                                    title="Grado"
                                    size="lg"
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    optionsFormatConfig={{ value: "id", label: "name" }}
                                    options={gradosNivelOptions ?? []}
                                    isLoading={isLoading.gradosNivel}
                                    placeholder="Todos"
                                    variant="gray-container"
                                    disabled={!gradosNivelOptions || isLoading.gradosNivel}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isMulti
                                    subtext={"Selección multiple"}
                                    withSelectAllOption
                                    selectAllOptionLabel={"Todos"}
                                />
                            </Col>
                        </>}
                    </Row>
                    {values.tipo_notificacion_id == tipoNotificacionId.SEGMENTADA && <>
                        <Row className="">
                            <Col sm={8} className="mb-3">
                                <AsyncSelect
                                    onChange={handleChange}
                                    id="arr_escuelas_cue"
                                    values={values}
                                    // errors={errors}
                                    // onBlur={handleBlur}
                                    placeholder={"Escribir cue"}
                                    size="lg"
                                    title="Escuela"
                                    getter={getEscuelas}
                                    paramKey={"id"}
                                    isMulti={true}
                                    variant="gray-container"
                                    optionsFormatConfig={{ value: "CUE", label: (el: EscuelaI) => `${el.name} (CUE: ${el.CUE})` }}
                                    defaultSelectedOptions={defaultOptionsSelected["default_selected_escuelas"]}
                                    disabled={!values.nivel_id}
                                    noOptionsMessage={`No hay opciones disponibles para el nivel seleccionado`}
                                    emptySelectMessage={`Ingresar un CUE`}
                                />
                            </Col>
                        </Row>
                    </>}

                    {values.tipo_notificacion_id && <>

                        <hr></hr>

                        <Row className="">
                            <Col sm={8} className="mb-3">
                                <Input
                                    id="notificacion_titulo"
                                    onChange={handleChange}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    onBlur={handleBlur}
                                    variant="gray-container"
                                    title="Título de notificación"
                                    size="lg"
                                    maxLength={60}
                                    required
                                />
                            </Col>
                            <Col sm={8} className="mb-3">
                                <Input
                                    id="notificacion_msg"
                                    onChange={handleChange}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    onBlur={handleBlur}
                                    variant="gray-container"
                                    title="Notificación"
                                    size="lg"
                                    type='textarea'
                                    maxLength={260}
                                    defaultHeight={"150px"}
                                    required
                                />
                            </Col>
                        </Row>

                        <hr></hr>

                        <Row>
                            <Col sm={4}>
                                <Radio
                                    title="¿Programar envío?"
                                    options={[{ label: "Sí", value: true }, { label: "No", value: false }]}
                                    id="isProgrammed"
                                    onChange={handleChange}
                                    values={values}
                                    optionElementColsConfig={{ sm: 6 }}
                                    style={{ maxWidth: 250 }}
                                />
                            </Col>
                            <Col sm={8} className="mb-3">
                                <Row>
                                    <Col sm={6} className="mb-3">
                                        <Input
                                            id="fecha"
                                            type="date"
                                            onChange={handleChange}
                                            values={values}
                                            variant="gray-container"
                                            errors={errors}
                                            onBlur={handleBlur}
                                            title="Fecha"
                                            touched={touched}
                                            required
                                            size="lg"
                                            disabled={!values.isProgrammed}
                                            minDate={format(new Date(), 'yyyy-MM-dd')}
                                        />
                                    </Col>
                                    <Col sm={6} className="mb-3">
                                        <Input
                                            id="hora"
                                            type="time"
                                            minutesStep={60}
                                            onChange={handleChange}
                                            values={values}
                                            variant="gray-container"
                                            errors={errors}
                                            onBlur={handleBlur}
                                            title="Hora"
                                            minTime={values.fecha && formatDate.formDateEsHoy(values.fecha) ? dateFunctions.redondearHoraActual() : "07:00"}
                                            maxTime="19:01"
                                            touched={touched}
                                            required
                                            size="lg"
                                            placeholder='Seleccionar hora'
                                            disabled={!values.isProgrammed}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <hr></hr>
                        <Row>
                            <Col sm={4}>
                                <Radio
                                    title="Notificar envío"
                                    options={[{ label: "Sí", value: true }, { label: "No", value: false }]}
                                    id="con_notificar_envio"
                                    onChange={handleChange}
                                    values={values}
                                    optionElementColsConfig={{ sm: 6 }}
                                    style={{ maxWidth: 250 }}
                                />
                            </Col>

                            <Col sm={8} className="mb-3">
                                <AsyncSelect
                                    onChange={handleChange}
                                    id="arr_correos_usuarios"
                                    values={values}
                                    arrElementsPath={"elementos"}
                                    size="lg"
                                    title="Correo del usuario"
                                    getter={(param) => getUsuarios({ limit: 99999, ...param })}
                                    required
                                    paramKey={"email"}
                                    isMulti={true}
                                    variant="gray-container"
                                    optionsFormatConfig={{ value: "email", label: "email" }}
                                    selectedElementColsConfig={{ lg: 6 }}
                                    disabled={!values.con_notificar_envio}
                                    onBlur={handleBlur}
                                    errors={errors}
                                    touched={touched}
                                    defaultSelectedOptions={defaultOptionsSelected["default_selected_correos_usuarios"]}
                                    placeholder='Seleccionar usuario'
                                />
                            </Col>
                        </Row>
                    </>}

                    <Row>
                        <Col>
                            <div className="w-100 d-flex column-gap-3 justify-content-end">
                                <Button variant="secondary" size={"lg"} onClick={handleReturn}>Cancelar</Button>
                                <Button variant="primary" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "Enviando..." : "Programar Envío"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </Card>

        </section >
    )
}
