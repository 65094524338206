import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

const PublicContainer = () => {
  return (
    <>
      {/* <ToastContainer position="bottom-right" /> */}
      <main role="main" className="form-public">
        <div className="mt-md-12 pt-md-5 pb-5">
           {/*  <Suspense fallback={null}>
              <Outlet />
            </Suspense> */}
                <Outlet />
        </div>
      </main>
    </>
  );
};

export default PublicContainer;
