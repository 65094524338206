import React from 'react'
import { Col, Row } from 'react-bootstrap'
import "./login.css"
import SignInForm from '../../components/auth/SignInForm'

export const Login = () => {
    const withTraditionalForm = process.env.REACT_APP_SHOW_TRADITIONAL_LOGIN_FORM == "true"

    return (
        <section className="login-section" style={{ overflowX: "hidden" }}>
            <Row>
                <Col>
                    <Row>
                        <Col lg={5} md={6} sm={12} className="d-flex flex-column justify-content-center">
                            <div className="login-text-container">
                                <h6>¡Te damos la bienvenida!</h6>
                                <h1>BackOffice <br /> miEscuela Familias</h1>
                                <h5 className="mt-2 mb-4">Desde acá vas a poder acceder al <b> BackOffice miEscuela Familias.</b></h5>
                                <div className="login-button-container mb-5">
                                    <SignInForm withTraditionalForm={withTraditionalForm} />
                                </div>
                            </div>
                        </Col>
                        <Col lg={7} md={6} sm={12}>
                            <div className="login-hero-image-container">
                                <img src="/assets/img/ilustracion-login.svg" alt="Mesa de Trabajo" />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    )
}
