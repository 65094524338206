import { infoFetchGetNotificacionI, makeNotificacion } from "dtos/notificacion"
import { ENDPOINTS } from "./endpoints"
import { sendRequest } from "./request/request.service"
import { servicesUtils } from "./utils/services.utils"
import { getResponseWithPagination } from "./request/request.interface"

export const getNotificacionById = async (id: number | string) => {
    const elemento = await sendRequest<infoFetchGetNotificacionI>({
        method: "GET",
        uri: ENDPOINTS.NOTIFICATIONS + "/" + id,
    })
    return elemento ? makeNotificacion(elemento) : undefined
}

export const getNotificaciones = async (params: any) => {

    const response = await sendRequest<getResponseWithPagination<infoFetchGetNotificacionI>>({
        method: "GET",
        uri: ENDPOINTS.NOTIFICATIONS,
        params: servicesUtils.removeNullParamsInFilters(params),
    })

    const elementosFormated = response.elementos.map((ctElemento) => makeNotificacion(ctElemento))
    return {
        ...response,
        elementos: elementosFormated,
    }
}

export const postNotificacion = async (params: any) => {
    const response = await sendRequest<infoFetchGetNotificacionI>({
        method: "POST",
        uri: ENDPOINTS.NOTIFICATIONS,
        params: servicesUtils.removeNullParamsInFilters(params),
    })

    return response
}

export const putNotificacion = async (params: any) => {
    const response = await sendRequest<infoFetchGetNotificacionI>({
        method: "PUT",
        uri: ENDPOINTS.NOTIFICATIONS,
        params: servicesUtils.removeNullParamsInFilters(params),
    })

    return response
}

export const deleteNotificacion = async (id: any) => {
    const response = await sendRequest({
        method: "DELETE",
        uri: ENDPOINTS.NOTIFICATION_DELETE + id,
    });
    return response;
};

export const getNotificacionesByUserId = async (id: any, params: any) => {
    const response = await sendRequest<getResponseWithPagination<infoFetchGetNotificacionI>>({
        method: "GET",
        uri: ENDPOINTS.NOTIFICATIONS_BY_USER_ID + id,
        params: servicesUtils.removeNullParamsInFilters(params),
    })
    // return response;
    const elementosFormated = response.elementos.map((ctElemento) => makeNotificacion(ctElemento))
    return {
        ...response,
        elementos: elementosFormated,
    }
}