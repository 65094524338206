import { Modal, Button } from 'react-bootstrap';

interface Props {
    showModal: boolean;
    handleCloseModal: () => void;
    handleConfirmDelete: () => void;
    isUserDelete?: boolean; 
}

export const ModalDelete = ({ showModal, handleCloseModal, handleConfirmDelete, isUserDelete }: Props) => {
    return (
        <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header>
                <Modal.Title>{isUserDelete ? "Eliminar usuario" : "Eliminar notificación"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                ¿Querés realizar esta acción?
            </Modal.Body>
            <Modal.Footer>
                <Button className="mx-3" variant="secondary" size="lg" onClick={handleCloseModal}>
                    Cancelar
                </Button>
                <Button variant="primary" size="lg" onClick={handleConfirmDelete}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
