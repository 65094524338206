import { Pagination } from 'components/Pagination/Pagination'
import { Select } from 'components/Select/Select'
import { useFormik } from 'formik'
import { NotificacionI } from 'interfaces/Notificacion'
import { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { PRIVATE_ROUTE } from 'routes/privateRoutes'
import { deleteNotificacion, getNotificaciones, getNotificacionesByUserId } from 'services/notificaciones'
import { fetchEstadosNotificacion, fetchEstadosNovedad, fetchNivelesEscuela, fetchTiposNotificacion } from 'store/slices/appSlice'
import { AppDispatch, RootState } from 'store/store'
import { DEFAULT_LIMIT_PAGINATION } from 'constants/appConstants'
import { Input } from 'components/Input/Input'
import { LupaIcon } from 'assets/icons/LupaIcon'
import { NotificacionesTable } from 'screens/Notificaciones/NotificacionesTable/NotificacionesTable'
import { getUsuarioById } from 'services/usuarios'
import "./usuarioProfile.css"
import { showErrorToast, showSuccessToast } from 'components/CustomAlerts/CustomToast'
import { NovedadesTable } from 'screens/Novedades/NovedadesTable/NovedadesTable'
import { NovedadI } from 'interfaces/Novedad'
import { deleteNovedad, getNovedadById, getNovedades, getNovedadesByUserId } from 'services/novedades'


export const UsuariosProfile = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [elementos, setElementos] = useState<NotificacionI[]>()
    const [elementosNovedades, setElementosNovdeades] = useState<NovedadI[]>()
    const [cantidadNotificaciones, setCantidadNotificaciones] = useState<number>(0)
    const [cantidadNovedades, setCantidadNovedades] = useState<number>(0)
    const [page, setPage] = useState<number>()
    const [limit, setLimit] = useState<number>()

    const [userData, setUserData] = useState<any>()
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const [ultimaNotificacion, setUltimaNotificacion] = useState<NotificacionI | null>(null);
    const [ultimaNovedad, setUltimaNovedad] = useState<NovedadI | null>(null);


    const {id} = useParams();
    const { estadosNovedad, nivelesEscuela, tiposNotificacion, estadosNotificacion, loadingAppData } = useSelector((st: RootState) => st.appData)

    const initialValuesNotificaciones = {
        tipoNotificacion: "",
        estadoNotificacion: "",
        titleNotificacion: ""
    }

    const initialValuesNovedades = {
        estadoNovedad: "",
        nivelNovedad: "",
        titleNovedad: ""
    }

    const formikNotificaciones = useFormik({
        initialValues: initialValuesNotificaciones,
        onSubmit: () => {},
    });

    const formikNovedades = useFormik({
        initialValues: initialValuesNovedades,
        onSubmit: () => {},
    });

    const searchNotificaciones = async (params?: { filtros?: { [key: string]: any } }) => {
        try {
            setIsLoading(true)
            console.log({ params })
            const response = await getNotificacionesByUserId(id, params?.filtros)
            setElementos(response.elementos)
            setCantidadNotificaciones(response.cantidad)
            setPage(response.page)
            setLimit(params?.filtros?.limit ?? DEFAULT_LIMIT_PAGINATION)

            if (response.elementos && response.elementos.length > 0) {
                setUltimaNotificacion(response.elementos[0]);
            }
        } catch (err) {
            showErrorToast("No fue posible cargar las notificaciones");
        } finally {
            setIsLoading(false)
        }
    }

    const searchNovedades = async (params?: { filtros?: { [key: string]: any } }) => {
        try {
            setIsLoading(true)
            console.log({ params, limit })
            const response = await getNovedadesByUserId(id, params?.filtros)
            
            setElementosNovdeades(response.elementos)
            setCantidadNovedades(response.cantidad)
            setPage(response.page)
            setLimit(params?.filtros?.limit ?? DEFAULT_LIMIT_PAGINATION)

            if (response.elementos && response.elementos.length > 0) {
                setUltimaNovedad(response.elementos[0]);
            }
        } catch (err) {
            showErrorToast("No fue posible cargar las novedades")
        } finally {
            setIsLoading(false)
        }
    }

    const handleEditNotificaciones = (notificacion: NotificacionI) => {
        navigate(PRIVATE_ROUTE.NOTIFICACIONES_EDITAR + "/" + notificacion.id, { state: { props: { notificacion } } })
    }
    const handleEditNovedades = (novedad: NovedadI) => {
        navigate(PRIVATE_ROUTE.NOVEDADES_EDITAR + "/" + novedad.id)
    }

    const handleDeleteNotificaciones = async (id: string | number) => {
        try {
            await deleteNotificacion(id);
            showSuccessToast("¡La notificación fue eliminada con éxito!");
            searchNotificaciones({
                filtros: {
                    ...formikNotificaciones.values,
                    page: 1,
                }
            })
        } catch (error) {
            showErrorToast("Error eliminando la notificación");
        }
    }

    const handleDeleteNovedades = async (id: string | number) => {
        try {
            await deleteNovedad(id);
            showSuccessToast("¡La novedad fue eliminada con éxito!");
            searchNovedades({
                filtros: {
                    ...formikNovedades.values,
                    page: 1,
                    limit,
                }
            })
        } catch (error) {
            showErrorToast("Error eliminando la novedad");
        }
    }

    const handleChangePaginationNotis = (newFiltros: { [key: string]: (string | number) }) => {
        searchNotificaciones({ filtros: { ...formikNotificaciones.values, ...newFiltros } })
    }

    const handleChangePaginationNews = (newFiltros: { [key: string]: (string | number) }) => {
        searchNovedades({ filtros: { ...formikNovedades.values, ...newFiltros } })
    }

    const handleClearFiltersNotis = () => {
        formikNotificaciones.resetForm(); 
        searchNotificaciones(); 
    }

    const handleClearFiltersNews = () => {
        formikNovedades.resetForm(); 
        searchNovedades(); 
    }

    const handleChangeWithResetPageNotis = (pageOne:any) => {
        setPage(1);
        formikNotificaciones.handleChange(pageOne);
    };

    const handleChangeWithResetPageNews = (pageOne:any) => {
        setPage(1);
        formikNovedades.handleChange(pageOne);
    };

    useEffect(() => {
        searchNotificaciones({ filtros: formikNotificaciones.values })
    }, [formikNotificaciones.values])

    useEffect(() => {
        searchNovedades({ filtros: { ...formikNovedades.values, limit, page } })
    }, [formikNovedades.values])

    useEffect(() => {
        if (!tiposNotificacion) dispatch(fetchTiposNotificacion());
        if (!estadosNotificacion) dispatch(fetchEstadosNotificacion());
        if (!estadosNovedad) dispatch(fetchEstadosNovedad());
        if (!nivelesEscuela) dispatch(fetchNivelesEscuela());
    }, [])
    
    useEffect(() => {
        getUsuarioById(id)
        .then((response) => {
            setUserData(response);
        })
        .catch((err) => {
            console.log( err);
         })
    }, [id]);
    

    return (
        <section>
            <h1 className="section-title">Perfil del usuario</h1>
            <h2 className="section-subtitle mt-2">Visualizá el perfil del usuario, las notificaciones enviadas y <br /> novedades cargadas.</h2>

            <div className='d-flex justify-content-between'>
                <div className='profile-cards'>
                    <div className='d-flex justify-content-start mx-3'>
                        <div className='profile-cards-img-container'>
                            <div className="profile-cards-img">
                                <img src={userData?.profileImageUrl} alt="BA Avatar"/>
                            </div>
                        </div>
                        <div>
                            <div className='profile-cards-text-container'>
                                <p className='profile-name mb-1'>{userData?.name} {userData?.lastname}</p>
                                <p className='profile-role mb-1'>{userData?.role.description}</p>
                                <p className='profile-email mb-1'>{userData?.email}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='profile-cards'>
                    <div className='d-flex justify-content-between align-items-center | profile-notifications-container'>
                        <p className='profile-notifications'>Notificaciones enviadas</p>
                        <span className='profile-notifications-number'>{cantidadNotificaciones ?? 0}</span>
                    </div>
                    <hr className='profile-cards-divider'/>
                    <div className='d-flex justify-content-between align-items-center | profile-notifications-container'>
                        <p className='profile-notifications'>Novedades publicadas</p>
                        <span className='profile-notifications-number'>{cantidadNovedades ?? 0}</span>
                        {/* <span className='profile-notifications-number'>-</span> */}
                    </div>
                </div>
                <div className='profile-cards'>
                        <p className='profile-notifications mx-3'>Última notificación enviada</p>
                        <hr className='profile-cards-divider'/>
                        <div className='d-flex justify-content-between align-items-center | profile-notifications-container'>
                            <p className='profile-date'>Título</p>
                            <span>{ultimaNotificacion?.title ?? "-"}</span>
                        </div>
                        <div className='d-flex justify-content-between align-items-center | profile-notifications-container'>
                            <p className='profile-date'>Fecha</p>
                            <span>{ultimaNotificacion ? new Date(ultimaNotificacion.createdAt).toLocaleDateString() : "-"}</span>
                        </div>
                    </div>
            </div>
            <h2 className="section-table-title mt-4 mb-1">Notificaciones</h2>

            <div className='d-flex justify-content-between mt-4 mb-3'>
                <div className='d-flex column-gap-3'>
                <Input
                        size="sm"
                        id="title"
                        onChange={handleChangeWithResetPageNotis}
                        placeholder='Buscar notificación'
                        values={formikNotificaciones.values}
                        startIcon={<LupaIcon />}
                    />
                    <Select
                        id="tipo_id"
                        values={formikNotificaciones.values}
                        onChange={handleChangeWithResetPageNotis}
                        optionsFormatConfig={{ value: "id", label: "name" }}
                        options={tiposNotificacion ?? []}
                        isLoading={loadingAppData.tiposNotificacion}
                        placeholder="Tipo"
                        width="150px"
                    />
                    <Select
                        id="estado_id"
                        values={formikNotificaciones.values}
                        onChange={handleChangeWithResetPageNotis}
                        optionsFormatConfig={{ value: "id", label: "name" }}
                        options={estadosNotificacion ?? []}
                        isLoading={loadingAppData.estadosNotificacion}
                        placeholder="Estado"
                        width="145px"
                    />
                    <Button size="sm" variant="secondary" className='clear-filters-button' onClick={handleClearFiltersNotis}>Limpiar filtros</Button>

                </div>
            </div>
           
            {isLoading && "IS LOADING"}

            {!isLoading && elementos && cantidadNotificaciones != undefined && cantidadNotificaciones > 0 &&
                <>
                    <NotificacionesTable
                        elementos={elementos}
                        handleDelete={handleDeleteNotificaciones}
                        handleEdit={handleEditNotificaciones}
                    />
                    <Pagination
                        totalItems={cantidadNotificaciones}
                        currentPage={page}
                        currentLimit={limit}
                        onChangePage={handleChangePaginationNotis}
                        showPaginationOnlyOnMoreThanPages={1}
                    />
                </>
            }

            {!isLoading && cantidadNotificaciones == 0 &&
                <Card className="mt-2">
                    No hay notificaciones que coincidan con la busqueda
                </Card>
            }
            
            

           
            <h2 className="section-table-title mt-3 mb-2">Novedades</h2>

            <div className='d-flex justify-content-between mt-4 mb-3'>
                <div className='d-flex column-gap-3'>
                    <Input
                        size="sm"
                        id="nivel_id"
                        onChange={handleChangeWithResetPageNews}
                        placeholder='Buscar novedad'
                        values={formikNovedades.values}
                        startIcon={<LupaIcon />}
                    />
                        <Select
                        id="estado_id"
                        values={formikNovedades.values}
                        onChange={handleChangeWithResetPageNews}
                        optionsFormatConfig={{ value: "id", label: "name" }}
                        options={estadosNovedad ?? []}
                        isLoading={loadingAppData.estadosNovedad}
                        placeholder="Estado"
                        width="145px"
                    />
                    <Select
                        id="nivel_id"
                        values={formikNovedades.values}
                        onChange={handleChangeWithResetPageNews}
                        optionsFormatConfig={{ value: "id", label: "name" }}
                        options={nivelesEscuela ?? []}
                        isLoading={loadingAppData.nivelesEscuela}
                        placeholder="Nivel"
                        width="150px"
                    />

                    <Button size="sm" variant="secondary" className='clear-filters-button' onClick={handleClearFiltersNews}>Limpiar filtros</Button>
                </div>
            </div>
             {isLoading && "IS LOADING"}

            {!isLoading && elementosNovedades && cantidadNovedades != undefined && cantidadNovedades > 0 &&
                <>
                    <NovedadesTable
                        elementos={elementosNovedades}
                        handleDelete={handleDeleteNovedades}
                        handleEdit={handleEditNovedades}
                    />
                    <Pagination
                        totalItems={cantidadNovedades}
                        currentPage={page}
                        currentLimit={limit}
                        onChangePage={handleChangePaginationNews}
                        showPaginationOnlyOnMoreThanPages={1}
                    />
                </>
            }

            {!isLoading && cantidadNovedades == 0 &&
                <Card className="mt-2">
                    No hay novedades que coincidan con la busqueda
                </Card>
            }
        </section>
    )
}
