import React from "react";
import { Breadcrumb, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { PRIVATE_ROUTE } from "../../../../routes/privateRoutes";
import { getBreadcrumbOptions } from "./breadcrumbOptions";
import useHasPermission from "hooks/useHasPermission";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { hasPermissions } from "utils/hasPermissions";

export const DashBreadcrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const auth = useSelector((state: RootState) => state.authData);

  

  const defaultOption = (
    <Breadcrumb.Item>
      <Link to={PRIVATE_ROUTE.INICIO}>Inicio</Link>
    </Breadcrumb.Item>
  );
  const currentOption = getBreadcrumbOptions(location.pathname);
  const checkPermissions = () => {
    const hasPermission = currentOption.every((ctePath)=>{
      if (!ctePath.permissions){
        return true
      }
      return hasPermissions(ctePath.permissions, auth.permissions??[])})
    return hasPermission
  }
  const handleVolver = () => {
    const volverPath = currentOption[currentOption.length - 2];
    if (volverPath.path) navigate(volverPath.path);
  };

  return (
    <div className="dash-breadcrumb-container">
      <Breadcrumb className="dash-breadcrumb">
        {/* {defaultOption} */}
        {currentOption && checkPermissions() &&
          currentOption.map((ctOption) => (
            <React.Fragment key={`breadcrumb-option-${ctOption.label}`}>
              {ctOption.path ? (
                <Breadcrumb.Item>
                  <Link to={ctOption.path}>{ctOption.label}</Link>
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item active>{ctOption.label}</Breadcrumb.Item>
              )}
            </React.Fragment>
          ))}
      </Breadcrumb>
      {/* {currentOption && currentOption.length > 1 && (
        <Button
          variant="text"
          className="with-backward-icon"
          onClick={handleVolver}
        >
          {" "}
          Volver
        </Button>
      )} */}
    </div>
  );
};
