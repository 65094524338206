import { NivelEscuelaI } from "interfaces/NivelEscuela";

export interface infoFetchGetNivelesEscuelaI {
    id: number | string;
    name: string;
    description: string;
}

class NivelEscuela implements NivelEscuelaI {
    id: number | string;
    name: string;
    description: string;

    constructor(infoFetch: infoFetchGetNivelesEscuelaI) {
        this.id = infoFetch.id;
        this.name = infoFetch.name;
        this.description = infoFetch.description;
    }
}

export const makeNivelEscuela = (infoFetch: infoFetchGetNivelesEscuelaI) => {
    return new NivelEscuela(infoFetch)
}