import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../store/slices/authSlice';
import "./navbar.css";
import { RootState } from '../../../../store/store';
import { Roles } from 'enums/Roles';
import { useState } from 'react';
import { ChevronHeaderIcon } from 'assets/icons/ChevronHeaderIcon';
import { LogOutIcon } from 'assets/icons/LogOutIcon';
import { PUBLIC_ROUTE } from 'routes/publicRoutes';
import { useNavigate } from 'react-router-dom';



export const Navbar = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.authData);
    
    const { } = useSelector((st: RootState) => st.appData)
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
        navigate(PUBLIC_ROUTE.LOGIN);
    }
    const handleToggle = () => {
        setIsOpen(prevState => !prevState);
    };
    // const handleChangeRol = (ctRol: RolI) => {
    //     dispatch(addAppData({
    //         "rol_seleccionado": ctRol
    //     }))
    // }

    // const handleChangeInstitucion = (ctInstitucion: InstitucionI) => {
    //     dispatch(addAppData({ "institucion_seleccionada": ctInstitucion }))
    //     handleChangeRol(ctInstitucion.roles[0])
    // }

    // const renderOpciones = () => {
    //     return (
    //         instituciones && institucion_seleccionada ?
    //             instituciones
    //                 .filter((ctInstitucion: InstitucionI) => ctInstitucion.id != institucion_seleccionada.id)
    //                 .map((ctInstitucion: InstitucionI) => <Dropdown.Item key={ctInstitucion.nombre} onClick={() => handleChangeInstitucion(ctInstitucion)}>{ctInstitucion.nombre}</Dropdown.Item>)
    //             :
    //             []
    //     )
    // }

    return (
        <ul className="navbar-nav">
            {/* <li className="nav-item">
                {instituciones && institucion_seleccionada && instituciones?.length > 1 ?
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" className="nav-link">
                            {institucion_seleccionada.nombre}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {renderOpciones()}
                        </Dropdown.Menu>
                    </Dropdown>
                    :
                    <div className="nav-link">{institucion_seleccionada?.nombre}</div>
                }
            </li>

            <li className="nav-item">
                {institucion_seleccionada && rol_seleccionado && institucion_seleccionada.roles.length > 1 ?
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" className="nav-link">
                            {rol_seleccionado.nombre}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                institucion_seleccionada.roles
                                    .filter((ctRol: RolI) => ctRol.id != rol_seleccionado.id)
                                    .map((ctRol: RolI) => <Dropdown.Item onClick={() => handleChangeRol(ctRol)}>{ctRol.nombre}</Dropdown.Item>)
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                    :
                    <div className="nav-link">{rol_seleccionado?.nombre}</div>
                }
            </li> */}

            <li className="nav-item">
                <Dropdown className="dropdownAvatar" onToggle={handleToggle}>
                    <Dropdown.Toggle id="dropdown-basic" className="nav-link">
                        <div className="avatar">
                            <img  src={auth.picture} alt="BA Avatar" width="50px" />
                        </div>
                        <ChevronHeaderIcon className={`${isOpen ? 'rotate-icon' : 'no-rotate'}`} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <div className='d-flex justify-content-start mt-2'>
                            <div className='dropdown-container mx-4'>
                                <div className="avatar-big">
                                    <img className="avatarImg" src={auth.picture} alt="BA Avatar" />
                                </div>
                            </div>
                            <div>
                            <div className='dropdown-text-container mx-1'>
                                <p className='nav-name'>{auth.name} {auth.lastname}</p>
                                <p className='nav-mail'>{auth.email}</p>
                                <p className='nav-role'>{Roles[auth.role]}</p>
                            </div>
                            </div>
                        </div>
                        <div className='dropdown-logout mt-2'>
                            <LogOutIcon className="my-2 mx-1"/>
                            <Dropdown.Item className='my-2' onClick={handleLogout}>Cerrar sesion</Dropdown.Item>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </li>
        </ul>
    )
}