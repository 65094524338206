import React from 'react'
import { Button } from 'react-bootstrap';
import "./selectedElement.css"
import { CloseIcon } from 'assets/icons/CloseIcon';
import { LISTA_COLORES_ICONOS } from 'assets/icons/LISTA_COLORES_ICONOS';

interface PropsI {
    handleRemove: any;
    label: string
}

export const SelectedElement = ({
    handleRemove,
    label,
}: PropsI) => {
    return (
        <div className="pill-card">
            {label}
            <Button
                variant="link"
                onClick={handleRemove}
            >
                <CloseIcon size={30} color={LISTA_COLORES_ICONOS.DARK_GRAY} />
            </Button>
        </div>
    )
}
