import { Navigate, Outlet, useLocation } from "react-router-dom";
import { PERMISOS } from "constants/permissions";
import useHasPermission from "hooks/useHasPermission";
import { BaseRedirection } from "screens/BaseRedirection/BaseRedirection";
import { Permisos } from "types/Permisos";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { PRIVATE_ROUTE } from "routes/privateRoutes";
import { PUBLIC_ROUTE } from "routes/publicRoutes";
import { AccesoRestringido } from "screens/ErrorScreens/AccesoRestringido";
interface Props {
  permissions: Permisos
}

const RequireAuth = ({ permissions = [] }: Props) => {
  const {isLogged} = useSelector((state: RootState) => state.authData)

  const hasPermission = useHasPermission(permissions)
  const location = useLocation()
  if(!isLogged) 
  return <Navigate to={PUBLIC_ROUTE.LOGIN} state={{ from: location }} replace />
  return hasPermission 
  ? <Outlet />
  : <AccesoRestringido />
}
export default RequireAuth;

