import * as yup from "yup";
import { DEFAULT_MESSAGES } from "../defaultMessages";


export const userSchema = yup.object().shape({
    email: yup
        .string()
        .email("Ingrese un email valido")
        .required(DEFAULT_MESSAGES.REQUIRED)
        .matches(/@bue\.edu\.ar$/, "El email debe terminar con @bue.edu.ar"),
    name: yup.string().min("1").max("25", "El nombre debe tener menos de 25 caracteres").required(DEFAULT_MESSAGES.REQUIRED),
    lastname: yup.string().min("1").max("25", "El apellido debe tener menos de 25 caracteres").required(DEFAULT_MESSAGES.REQUIRED),
    areaId: yup.number().required(DEFAULT_MESSAGES.REQUIRED),
    roleId: yup.number().required(DEFAULT_MESSAGES.REQUIRED),
})