import React from 'react'

interface Props {
    size?: number;
    className?: string;
}
export const ChevronPageIcon = ({
    size = 20,
}: Props) => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="#526071" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><polyline points="9 18 15 12 9 6"></polyline></svg>    )
}