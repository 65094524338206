import { LISTA_COLORES_ICONOS } from './LISTA_COLORES_ICONOS';

interface Props {
    color?: (typeof LISTA_COLORES_ICONOS)[keyof typeof LISTA_COLORES_ICONOS];
    size?: number;
}

export const NovedadesIcon = ({
    color = LISTA_COLORES_ICONOS.BLUE_ACTIVO,
    size = 20,
}: Props) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20">
            <path id="Trazado_55" data-name="Trazado 55" d="M0,0H20V20H0Z" fill="none" />
            <path id="history_edu_FILL1_wght400_GRAD0_opsz24" d="M64.667-788.513a1.382,1.382,0,0,1-1.014-.422,1.383,1.383,0,0,1-.422-1.014V-792.1h2.154v-1.615A3.515,3.515,0,0,1,64.191-794a3.156,3.156,0,0,1-1.032-.727v-.79h-.826L60-797.846a3.5,3.5,0,0,1,1.6-1.167,5.656,5.656,0,0,1,1.921-.341,6.075,6.075,0,0,1,.942.072,3.937,3.937,0,0,1,.924.269V-800H74v9.333a2.077,2.077,0,0,1-.628,1.526,2.077,2.077,0,0,1-1.526.628Zm2.154-3.59h4.308v1.436a.7.7,0,0,0,.206.511.7.7,0,0,0,.512.206.7.7,0,0,0,.512-.206.7.7,0,0,0,.206-.511v-7.9H66.821v.431l4.308,4.308v1.005H70.123l-2.046-2.046-.144.144a4.132,4.132,0,0,1-.529.449,2.367,2.367,0,0,1-.583.305Zm-3.877-4.846h1.651v1.544a1.356,1.356,0,0,0,.449.2,2.153,2.153,0,0,0,.485.054,2.091,2.091,0,0,0,.745-.126,1.89,1.89,0,0,0,.655-.449l.144-.144-1.005-1.005a3.478,3.478,0,0,0-1.167-.781,3.664,3.664,0,0,0-1.382-.26,4.148,4.148,0,0,0-.682.054,4.268,4.268,0,0,0-.646.162Z" transform="translate(-56.999 804.256)" fill={color} />
        </svg>

    )
}
