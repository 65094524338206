import { OptionsIcon } from 'assets/icons/OptionsIcon';
import { ModalDelete } from 'components/ModalDelete/ModalDelete';
import { NovedadI } from 'interfaces/Novedad';
import { useEffect, useState } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNivelesEscuela } from 'store/slices/appSlice';
import { AppDispatch, RootState } from 'store/store';
import { formatDate } from 'utils/formatUtils';
interface Props {
    novedad: NovedadI,
    handleShowDetailModalNews: (id: number | string) => void,
    handleDelete: (id: number | string) => void,
    handleEdit: (novedad: NovedadI) => void,
}

export const NovedadesRow = ({ novedad, handleShowDetailModalNews, handleDelete, handleEdit }: Props) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const { nivelesEscuela } = useSelector((st: RootState) => st.appData)

    const handleShowDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleConfirmDelete = () => {
        handleDelete(novedad.id);
        handleCloseDeleteModal();
    };
    const renderSchoolLevels = () => {
        if(novedad.schoolLevels.map(ctLv => ctLv.name).length === nivelesEscuela?.length ){
            return "Todos los niveles"
        } else{
            const schoolLevel = novedad.schoolLevels.map(ctLv => ctLv.name).join(" ");
            return schoolLevel;
        }
    }

    useEffect(() => {
        if (!nivelesEscuela) dispatch(fetchNivelesEscuela());
    }, [])
    return (
        <>
            <tr className='table-row' key={`NotificacionesTable-${novedad.title}`}>
                <td onClick={()=>console.log(novedad)}>{novedad.title}</td>
                <td>{novedad.subtitle}</td>
                <td>{renderSchoolLevels()}</td>
                <td>{formatDate.stringDate(novedad.createdAt)}</td>
                <td>
                    <Badge bg={"none"}
                        style={{
                            color: novedad.newState?.color,
                            backgroundColor: novedad.newState?.backgroundColor,
                            borderColor: novedad.newState?.borderColor,
                        }}>
                        {novedad.newState?.name}
                    </Badge>
                </td>
                <td>
                    <Dropdown className="acciones-dropdown | d-flex justify-content-center">
                        <Dropdown.Toggle>
                            <OptionsIcon />
                        </Dropdown.Toggle>
                        
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleShowDetailModalNews(novedad.id)}>Ver novedad</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleEdit(novedad)}>Editar</Dropdown.Item>
                            <Dropdown.Item onClick={handleShowDeleteModal}>Eliminar</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>

            <ModalDelete
                showModal={showDeleteModal}
                handleCloseModal={handleCloseDeleteModal}
                handleConfirmDelete={handleConfirmDelete}
                isUserDelete={false}
            />
        </>
    )
}
