import { BellIcon } from "assets/icons/BellIcon";
import { InicioIcon } from "assets/icons/InicioIcon";
import { NewUserIcon } from "assets/icons/NewUserIcon";
import { NovedadesIcon } from "assets/icons/NovedadesIcon";
import { PRIVATE_ROUTE } from "../../../../routes/privateRoutes";
import { LISTA_COLORES_ICONOS } from "assets/icons/LISTA_COLORES_ICONOS";
import { PERMISOS } from "constants/permissions";
import { ReactNode } from "react";
import { Permisos } from "types/Permisos";

interface renderIconProps {
    color: (typeof LISTA_COLORES_ICONOS)[keyof typeof LISTA_COLORES_ICONOS]
}

interface NavOption {
    renderIcon: (props: renderIconProps) => ReactNode
    label: string;
    path: string;
    bottomHr: boolean;
    permissions: Permisos
}


export const NAV_OPTIONS: { [key: string]: NavOption } = {
    INICIO: {
        renderIcon: ({ color }: renderIconProps) => <InicioIcon color={color} />,
        label: "Inicio",
        path: PRIVATE_ROUTE.INICIO,
        bottomHr: false,
        permissions: [PERMISOS.VIEW_HOME]
    },
    NOTIFICACIONES: {
        renderIcon: ({ color }: renderIconProps) => <BellIcon color={color} />,
        label: "Notificaciones",
        path: PRIVATE_ROUTE.NOTIFICACIONES,
        bottomHr: true,
        permissions: [PERMISOS.VIEW_NOTIFICATION]
    },
    NOVEDADES: {
        renderIcon: ({ color }: renderIconProps) => <NovedadesIcon color={color} />,
        label: "Novedades",
        path: PRIVATE_ROUTE.NOVEDADES,
        bottomHr: false,
        permissions: [PERMISOS.VIEW_HOME]
    },
    USUARIOS: {
        renderIcon: ({ color }: renderIconProps) => <NewUserIcon color={color} />,
        label: "Usuarios",
        path: PRIVATE_ROUTE.USUARIOS,
        bottomHr: false,
        permissions: [PERMISOS.VIEW_USER]
    },
}