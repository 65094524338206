import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { PUBLIC_ROUTE } from './publicRoutes'
import { Notificaciones } from 'screens/Notificaciones/Notificaciones'
import { PRIVATE_ROUTE } from './privateRoutes'
import { Novedades } from 'screens/Novedades/Novedades'
import RequireAuth from 'components/RequireAuth'
import Missing from 'screens/Missing'
import Home from 'screens/Home/Home'
import { PERMISOS } from 'constants/permissions'
import PublicContainer from '../containers/public/PublicContainer';
import { Dashboard } from 'containers/dashboard/Dashboard'
import { NotificacionesABM } from 'screens/Notificaciones/NotificacionesABM/NotificacionesABM'
import { Usuarios } from 'screens/Usuarios/Usuarios'
import { Login } from 'screens/Login/Login'
import { BaseRedirection } from 'screens/BaseRedirection/BaseRedirection'
import { UsuariosProfile } from 'screens/Usuarios/UsuariosProfile/UsuariosProfile'
import { NovedadesABM } from 'screens/Novedades/NovedadesABM/NovedadesABM'
import { PaginaNoEncontrada } from 'screens/ErrorScreens/PaginaNoEncontrada'

const isAuthenticated = () => {
    return !!localStorage.getItem('authToken'); 
};

const AppRouter = () => {

	const location = useLocation()
	const props = location.state?.props ?? {}

	return (
		<>
			<Routes>
			<Route path="/" element={
				isAuthenticated()
					? <Navigate to={PRIVATE_ROUTE.INICIO} />
					: <Navigate to={PUBLIC_ROUTE.LOGIN} />
			} />

				<Route element={<PublicContainer />}>
					<Route path={PUBLIC_ROUTE.LOGIN} element={<Login />} />
				</Route>

				<Route element={<Dashboard />}>
					<Route element={<RequireAuth permissions={[PERMISOS.VIEW_HOME]} />}>
						<Route path={PRIVATE_ROUTE.INICIO} element={<Home />} />
					</Route>

					<Route element={<RequireAuth permissions={[PERMISOS.VIEW_NOTIFICATION]} />}>
						<Route
							path={PRIVATE_ROUTE.NOTIFICACIONES_CREAR}
							element={<NotificacionesABM />}
						/>
					</Route>

					<Route element={<RequireAuth permissions={[PERMISOS.VIEW_NOTIFICATION]} />}>
						<Route
							path={PRIVATE_ROUTE.NOTIFICACIONES_EDITAR + "/:id"}
							element={<NotificacionesABM {...props} typeOfAction="edit" />}
						/>
					</Route>

					<Route element={<RequireAuth permissions={[PERMISOS.VIEW_NOTIFICATION]} />}>
						<Route
							path={PRIVATE_ROUTE.NOTIFICACIONES}
							element={<Notificaciones />}
						/>
					</Route>
					
					
					<Route element={<RequireAuth permissions={[PERMISOS.VIEW_HOME]} />}>
						<Route
							path={PRIVATE_ROUTE.NOVEDADES_CREAR}
							element={<NovedadesABM />}
						/>
					</Route>

					<Route element={<RequireAuth permissions={[PERMISOS.VIEW_HOME]} />}>
						<Route
							path={PRIVATE_ROUTE.NOVEDADES_EDITAR + "/:id"}
							element={<NovedadesABM {...props} typeOfAction="edit" />}
						/>
					</Route>
					
					<Route element={<RequireAuth permissions={[PERMISOS.VIEW_HOME]} />}>
						<Route
							path={PRIVATE_ROUTE.NOVEDADES}
							element={<Novedades />}
						/>
					</Route> 

					<Route element={<RequireAuth permissions={[PERMISOS.VIEW_USER]} />}>
						<Route
							path={PRIVATE_ROUTE.USUARIOS}
							element={<Usuarios />}
						/>
					</Route>
					<Route element={<RequireAuth permissions={[PERMISOS.VIEW_USER]} />}>
						<Route
							path={PRIVATE_ROUTE.PERFIL_USUARIOS}
							element={<UsuariosProfile />}
						/>
					</Route>
				</Route>
				{/* </Route> */}

				<Route path="*" element={<PaginaNoEncontrada />} />
			</Routes>
		</>
	)
}


export default AppRouter