import { Chart as ChartJS, Legend, ArcElement } from "chart.js"; 
import { Pie } from "react-chartjs-2";

ChartJS.register(Legend, ArcElement);

interface MetricsChartProps {
    notificaciones: number;
    novedades: number;
}

export const MetricsChart = ({ notificaciones, novedades }: MetricsChartProps) => {
    const total = notificaciones + novedades;
    const notificacionesPorcentaje = total === 0 ? 0 : (notificaciones / total) * 100;
    const novedadesPorcentaje = total === 0 ? 0 : (novedades / total) * 100;

    const data = {
        datasets: [{
            label: "Métricas",
            data: [notificacionesPorcentaje, novedadesPorcentaje],
            backgroundColor: ["#EF4D44", "#F7941D"],
            borderWidth: 0, 
        }],
        labels: []
    };

    const options = {};

    return <Pie options={options} data={data} />;
};
