
interface Props {
    size?: number;
}

export const SuccessIcon = ({
    size = 24,
}: Props) => {
    return (
        <svg id="Grupo_28675" data-name="Grupo 28675" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
            <rect id="Rectángulo_16" data-name="Rectángulo 16" width="24" height="24" fill="none"/>
            <path id="Trazado_11245" data-name="Trazado 11245" d="M444,336a10,10,0,1,0,10,10A10,10,0,0,0,444,336Zm-2.71,14.29-3.59-3.59a1,1,0,0,1,1.41-1.41l2.89,2.88,6.88-6.88a1,1,0,0,1,1.41,1.41l-7.59,7.59A1,1,0,0,1,441.29,350.29Z" transform="translate(-432.067 -334.352)" fill="#26874a"/>
        </svg>

    )
}
