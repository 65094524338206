import * as yup from "yup";
import { DEFAULT_MESSAGES } from "../defaultMessages";

// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

const VALID_EMAIL_STRING = "Por favor introduzca un correo electrónico válido";
const ONLY_LETTERS_STRING = "Por favor ingrese solo letras";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email(VALID_EMAIL_STRING)
    .required(DEFAULT_MESSAGES.REQUIRED),
  // password: yup
  //   .string()
  //   .required(DEFAULT_MESSAGES.REQUIRED),
});
