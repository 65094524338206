import { Pagination } from 'components/Pagination/Pagination'
import { Select } from 'components/Select/Select'
import { useFormik } from 'formik'
import { NotificacionI } from 'interfaces/Notificacion'
import { useEffect, useState } from 'react'
import { Button, Card, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PRIVATE_ROUTE } from 'routes/privateRoutes'
import { deleteNotificacion, getNotificaciones } from 'services/notificaciones'
import { fetchEstadosNotificacion, fetchTiposNotificacion } from 'store/slices/appSlice'
import { AppDispatch, RootState } from 'store/store'
import { NotificacionesTable } from './NotificacionesTable/NotificacionesTable'
import { DEFAULT_LIMIT_PAGINATION } from 'constants/appConstants'
import { Input } from 'components/Input/Input'
import { LupaIcon } from 'assets/icons/LupaIcon'
import { showErrorToast,showSuccessToast } from 'components/CustomAlerts/CustomToast'
import { title } from 'process'

export const Notificaciones = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [elementos, setElementos] = useState<NotificacionI[]>()
    const [cantidad, setCantidad] = useState<number>()
    const [page, setPage] = useState<number>()
    const [limit, setLimit] = useState<number>()

    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const { tiposNotificacion, estadosNotificacion, loadingAppData } = useSelector((st: RootState) => st.appData)

    const initialValues = {
        tipoNotificacion: "",
        estadoNotificacion: "",
        title: "",
    }

    const {
        values,
        handleChange,
        resetForm,
    } = useFormik({
        initialValues,
        onSubmit: () => { },
    });
    const { role, area } = useSelector((st: RootState) => st.authData);

    const searchNotificaciones = async (params?: { filtros?: { [key: string]: any } }) => {
        const adminFilter =  (role === 2)? { area_id: area} : {}
        const notifierFilter =  (role === 3)? { area_id: area} : {}
        try {
            setIsLoading(true)
            console.log({ params, limit })
            const response = await getNotificaciones({...params?.filtros, ...adminFilter, ...notifierFilter})
            
            setElementos(response.elementos)
            setCantidad(response.cantidad)
            setPage(response.page)
            setLimit(params?.filtros?.limit ?? DEFAULT_LIMIT_PAGINATION)
        } catch (err) {
            showErrorToast("No fue posible cargar las notificaciones");
        } finally {
            setIsLoading(false)
        }
    }

    const handleEdit = (notificacion: NotificacionI) => {
        navigate(PRIVATE_ROUTE.NOTIFICACIONES_EDITAR + "/" + notificacion.id/* , { state: { props: { notificacion } } } */)
    }

    const handleDelete = async (id: string | number) => {
        try {
            await deleteNotificacion(id);
            showSuccessToast("¡La notificación fue eliminada con éxito!");
            searchNotificaciones({
                filtros: {
                    ...values,
                    page: 1,
                    limit,
                }
            })
        } catch (error) {
            showErrorToast("Error eliminando la notificación");
        }
    }

    const handleChangePagination = (newFiltros: { [key: string]: (string | number) }) => {
        searchNotificaciones({ filtros: { ...values, ...newFiltros } })
    }

    const handleClearFilters = () => {
        resetForm(); 
        searchNotificaciones(); 
    }

    const handleClickCrear = () => {
        navigate(PRIVATE_ROUTE.NOTIFICACIONES_CREAR)
    }

    const handleChangeWithResetPage = (pageOne:any) => {
        setPage(1);
        handleChange(pageOne);
    };
    
    useEffect(() => {
        searchNotificaciones({ filtros: { ...values, limit, page } });
    }, [values]);
    
    useEffect(() => {
        if (!tiposNotificacion) dispatch(fetchTiposNotificacion());
        if (!estadosNotificacion) dispatch(fetchEstadosNotificacion());
    }, [])

    return (
        <section>
            <h1 className="section-title" onClick={() => console.log({ limit })}>Notificaciones</h1>

            <h2 className="section-subtitle mt-2"> Enviá una nueva notificación y gestioná las notificaciones enviadas.</h2>

            <div className='d-flex justify-content-between mt-4 mb-3'>
                <div className='d-flex column-gap-3'>
                    <Input
                        size="sm"
                        id="title"
                        onChange={handleChangeWithResetPage}
                        placeholder='Buscar notificación'
                        values={values}
                        startIcon={<LupaIcon />}
                    />
                    <Select
                        id="tipo_id"
                        values={values}
                        onChange={handleChangeWithResetPage}

                        optionsFormatConfig={{ value: "id", label: "name" }}
                        options={tiposNotificacion ?? []}
                        isLoading={loadingAppData.tiposNotificacion}
                        placeholder="Tipo"
                        width="150px"
                    />
                    <Select
                        id="estado_id"
                        values={values}
                        onChange={handleChangeWithResetPage}

                        optionsFormatConfig={{ value: "id", label: "name" }}
                        options={estadosNotificacion ?? []}
                        isLoading={loadingAppData.estadosNotificacion}
                        placeholder="Estado"
                        width="145px"
                    />
                    <Button size="sm" variant="secondary" className='clear-filters-button' onClick={handleClearFilters}>Limpiar filtros</Button>
                </div>
                <Button size="lg" onClick={handleClickCrear}>Nueva notificación</Button>
            </div>

            {isLoading && <div className="d-flex justify-content-center w-full"><Spinner /></div>}

            {!isLoading && elementos && cantidad != undefined && cantidad > 0 &&
                <>
                    <NotificacionesTable
                        elementos={elementos}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                    />
                    <Pagination
                        totalItems={cantidad}
                        currentPage={page}
                        currentLimit={limit}
                        onChangePage={handleChangePagination}
                        showPaginationOnlyOnMoreThanPages={1}
                    />
                </>
            }

            {!isLoading && cantidad == 0 &&
                <Card className="mt-2">
                    No hay notificaciones que coincidan con la busqueda
                </Card>
            }

        </section>
    )
}
