import axios from "axios";

const BASE_URL = process.env.REACT_APP_API;

export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "Refresh-Sent": "false",
    }
})

interface SendRequestP {
    method: "POST" | "GET" | "PATCH" | "DELETE" | "PUT";
    uri: string;
    params?: { [key: string]: string } | object
    signal?: AbortSignal
}

export async function sendRequest<T>({
    method,
    uri,
    params = {},
    signal,
}: SendRequestP): Promise<T> {

    switch (method) {
        case "POST": {
            const response: T = await axiosInstance.post(uri, params, { signal });
            return response;
        }

        case "GET": {
            const response: T = await axiosInstance.get(uri, { params, signal })
            return response
        }

        case "PATCH": {
            const response: T = await axiosInstance.patch(uri, params, { signal })
            return response;
        }

        case "DELETE": {
            return await axiosInstance.delete(uri, { data: params })
        }

        case "PUT": {
            const response: T = await axiosInstance.put(uri, params, { signal })
            return response;
        }

        default: {
            throw new Error("Falta method del fetch")
        }
    }
}