import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import AppRouter from "./routes/AppRouter";
import "./styles/general.css";
import "./styles/palette.css";
import "./styles/text.css";
import "./styles/variables.css";

/* const Home = lazy(() => import("./screens/Home/Home"));
const CrearGrilla = lazy(() => import("./screens/CrearGrilla/CrearGrilla"));
const DocentesSearch = lazy(() =>
  import("./screens/Docentes/DocentesSearch/DocentesSearch")
);
const DocentesEdit = lazy(() =>
  import("./screens/Docentes/DocentesEdit/DocentesEdit")
); */

function App() {
  const location = useLocation();
  const props = location.state?.props ?? {};

  return (
    <>
      <Header />
      <ToastContainer position="bottom-right" hideProgressBar />
      <AppRouter />
      <Footer />
    </>
  );
}

export default App;
