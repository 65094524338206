import { ENDPOINTS } from "./endpoints"
import { sendRequest } from "./request/request.service"
import { servicesUtils } from "./utils/services.utils"
import { getResponseWithPagination } from "./request/request.interface"
import { infoFetchGetUsuariosI, makeUsuario } from "dtos/usuarios"

export const getUsuarios = async (params: any) => {
    const response = await sendRequest<getResponseWithPagination<infoFetchGetUsuariosI>>({
        method: "GET",
        uri: ENDPOINTS.USERS,
        params: servicesUtils.removeNullParamsInFilters(params),
    })
    const elementosFormated = response.elementos.map((ctElemento) => makeUsuario(ctElemento))
    return {
        page: response.page,
        cantidad: response.cantidad,
        elementos: elementosFormated,
    }
}

export const postUsuario = async (body: any) => {
    const response = await sendRequest({
        method: "POST",
        uri: ENDPOINTS.USER_CREATE,
        params: body,
    });

    return response;
};

export const deleteUsuario = async (id: any) => {
    const response = await sendRequest({
        method: "DELETE",
        uri: ENDPOINTS.USER_DELETE+id,
    });

    return response;
};

export const editarUsuario = async (body: any) => {
    const response = await sendRequest({
        method: "PUT",
        uri: ENDPOINTS.USER_EDIT,
        params: body,
    });

    return response;
};

export const getUsuarioById = async (id: any) => {
    const response = await sendRequest({
        method: "GET",
        uri: ENDPOINTS.USER_BY_ID + id,
    });

    return response;
};