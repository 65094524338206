import { createSlice } from "@reduxjs/toolkit";
import { COOKIES_NAME } from "constants/appConstants";
import Cookies from 'js-cookie'
import { jwtDecode } from "jwt-decode";
import { AuthTokens } from "services/auth";
import { Permisos } from "types/Permisos";
import { Roles } from "enums/Roles";

interface basicUserInfo {
    id: number;
    name: string;
    lastname: string;
    permissions: Permisos | null;
    picture: string;
    role: Roles;
    area: number;
    email: string;
}

export interface authStateInterface extends basicUserInfo {
    isLogged: boolean,
    accessToken: string,
    refreshToken: string,
}

const defaultState: authStateInterface = {
    id: 0,
    name: "",
    lastname: "",
    permissions: null,
    picture: "",
    role: Roles.Notificador,
    email:"",
    isLogged: false,
    accessToken: "",
    refreshToken: "",
    area: 2,
};

const savedTokens = Cookies.get(COOKIES_NAME);

const getUserData = (savedTokens: AuthTokens) => {
    const userInfo: basicUserInfo = jwtDecode(savedTokens.accessToken)
    console.log({ userInfo })
    return {
        ...userInfo,
        ...savedTokens,
        isLogged: true,
        area: userInfo.area, 
    };
}

const initialState: authStateInterface = savedTokens && savedTokens !== "undefined" ? getUserData(JSON.parse(savedTokens)) : defaultState

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            const tokens: AuthTokens = action.payload
            Cookies.set(COOKIES_NAME, JSON.stringify(tokens))
            return getUserData(tokens)
        },
        loadToken: (state, action) => {
            const token = action.payload.token
            return getUserData(token)
        },
        logout: (state) => {
            Cookies.remove(COOKIES_NAME)
            return defaultState
        },
    },
});

export const { login, logout, loadToken } = authSlice.actions;

export default authSlice.reducer;
