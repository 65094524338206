import * as yup from "yup";
import { DEFAULT_MESSAGES } from "../defaultMessages";
import { tipoNotificacionId } from '../../screens/Notificaciones/NotificacionesABM/NotificacionesABM';

const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/; // Regex para validar formato de hora HH:mm


export const createNotificacionSchema = yup.object({
    tipo_notificacion_id: yup.number().required(DEFAULT_MESSAGES.REQUIRED),

    notificacion_titulo: yup.string().required(DEFAULT_MESSAGES.REQUIRED),
    notificacion_msg: yup.string().required(DEFAULT_MESSAGES.REQUIRED),

    nivel_id: yup.number().when(["tipo_notificacion_id"], {
        is: (tipo_notificacion_id: number) => tipo_notificacion_id == tipoNotificacionId.SEGMENTADA,
        then: (schema) => schema.required(DEFAULT_MESSAGES.REQUIRED),
        otherwise: (schema) => schema.nullable(),
    }),

    // arr_grados_id: yup.array().of(yup.number()).when(["tipo_notificacion_id"], {
    //     is: (tipo_notificacion_id: number) => tipo_notificacion_id == tipoNotificacionId.SEGMENTADA,
    //     then: (schema) => schema.required(DEFAULT_MESSAGES.REQUIRED),
    //     otherwise: (schema) => schema.nullable(),
    // }),

    isProgrammed: yup.boolean(),
    fecha: yup.date().when(
        ['isProgrammed'],
        {
            is: (isProgrammed: boolean) => isProgrammed,
            then: (schema) => schema.required(DEFAULT_MESSAGES.REQUIRED),
            otherwise: (schema) => schema.nullable(),
        }
    ),
    hora: yup.string().when(
        ['isProgrammed'],
        {
            is: (isProgrammed: boolean) => isProgrammed,
            then: (schema) => schema
                .matches(timeRegex, DEFAULT_MESSAGES.INVALID_TIME)
                .required(DEFAULT_MESSAGES.REQUIRED),
            otherwise: (schema) => schema.nullable(),
        }
    ),

    con_notificar_envio: yup.boolean(),
    arr_correos_usuarios: yup.array().when(
        ["con_notificar_envio"],
        {
            is: (con_notificar_envio: boolean) => con_notificar_envio,
            then: (schema) => schema
                .of(yup.string().required(DEFAULT_MESSAGES.REQUIRED))
                .min(1, DEFAULT_MESSAGES.REQUIRED),
            otherwise: (schema) => schema.nullable(),
        }
    )
});


// arr_grados_id: [],
// arr_escuelas_cue: [],
// arr_correos_usuarios: [],
// con_notificar_envio: false,

