
// const adaptRoutePath = () => {
// 	"/crear-grilla"
// 	"/docentes/editar/:id"
// 	"/docentes/editar/:id/materias/:materiaId"
// 	return
// 	"/crear-grilla"
// 	"/docentes/editar/n"
// 	"/docentes/editar/n/materias/n"
// }


export const PRIVATE_ROUTE = {
	INICIO: "/inicio",

	NOTIFICACIONES: "/notificaciones",
	NOTIFICACIONES_CREAR: "/notificaciones/crear",
	NOTIFICACIONES_EDITAR: "/notificaciones/editar",
	NOVEDADES: "/novedades",
	NOVEDADES_CREAR: "/novedades/crear",
	NOVEDADES_EDITAR: "/novedades/editar",

	USUARIOS: "/usuarios",
	PERFIL_USUARIOS: "/usuarios/:id",
};
