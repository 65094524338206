import { LISTA_COLORES_ICONOS } from './LISTA_COLORES_ICONOS';

interface Props {
    color?: (typeof LISTA_COLORES_ICONOS)[keyof typeof LISTA_COLORES_ICONOS];
    size?: number;
}

export const InicioIcon = ({
    color = LISTA_COLORES_ICONOS.BLUE_ACTIVO,
    size = 20,
}: Props) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20">
            <path id="Trazado_55" data-name="Trazado 55" d="M0,0H20V20H0Z" fill="none" />
            <path id="Icon_awesome-list" data-name="Icon awesome-list" d="M2.188,12.125H.438A.437.437,0,0,0,0,12.563v1.75a.438.438,0,0,0,.438.438h1.75a.438.438,0,0,0,.438-.437v-1.75A.437.437,0,0,0,2.188,12.125Zm0-8.75H.438A.438.438,0,0,0,0,3.813v1.75A.437.437,0,0,0,.438,6h1.75a.437.437,0,0,0,.438-.437V3.813A.438.438,0,0,0,2.188,3.375Zm0,4.375H.438A.438.438,0,0,0,0,8.188v1.75a.437.437,0,0,0,.438.438h1.75a.437.437,0,0,0,.438-.437V8.188A.438.438,0,0,0,2.188,7.75Zm11.375,4.813H4.813A.438.438,0,0,0,4.375,13v.875a.437.437,0,0,0,.438.438h8.75A.437.437,0,0,0,14,13.875V13A.437.437,0,0,0,13.563,12.563Zm0-8.75H4.813a.438.438,0,0,0-.438.438v.875a.438.438,0,0,0,.438.437h8.75A.437.437,0,0,0,14,5.125V4.25A.437.437,0,0,0,13.563,3.813Zm0,4.375H4.813a.438.438,0,0,0-.438.438V9.5a.437.437,0,0,0,.438.438h8.75A.437.437,0,0,0,14,9.5V8.625A.437.437,0,0,0,13.563,8.188Z" transform="translate(3.001 0.938)" fill={color} />
        </svg>
    )
}
