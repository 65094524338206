import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ChevronIcon } from "../../../../images/svg-jsx/ChevronIcon";
import { NAV_OPTIONS } from "./navOptions";
import { LISTA_COLORES_ICONOS } from "assets/icons/LISTA_COLORES_ICONOS";
import { ComponentGuard } from "routes/guards/ComponentGuard";

const ARR_NAV_OPTIONS = Object.values(NAV_OPTIONS);

export const DashNav = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(st => !st);
    };

    const location = useLocation();
    
    const secondLastIndex = ARR_NAV_OPTIONS.length - 2; // Índice del penúltimo elemento

    return (
        <div className={`dash-navbar ${isExpanded ? "show" : ""}`}>
            <button className="dash-nav-toggle-btn" onClick={handleToggle}>
                <ChevronIcon direction={isExpanded ? "left" : "right"} />
            </button>
            <nav className="dash-nav-list">
                <ul>
                    {ARR_NAV_OPTIONS.map((ctOption, index) => (
                        <React.Fragment key={`dash-nav-option-${ctOption.label}`}>
                            <ComponentGuard permissions={ctOption.permissions}>
                                <li>
                                    <NavLink
                                        className={({ isActive }: { isActive: boolean }) => isActive ? "dash-nav-link active" : "dash-nav-link"}
                                        to={ctOption.path}
                                    >
                                        {ctOption.renderIcon({ color: location.pathname.includes(ctOption.path) ? LISTA_COLORES_ICONOS.BLUE_ACTIVO : LISTA_COLORES_ICONOS.GRAY_INACTIVO })}
                                        <span className="dash-nav-name">{ctOption.label}</span>
                                    </NavLink>
                                </li>
                                {index === secondLastIndex && <hr className="dash-nav-hr" />}
                            </ComponentGuard>
                        </React.Fragment>
                    ))}
                </ul>
            </nav>
        </div>
    );
};
