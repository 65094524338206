import { NotificacionI } from 'interfaces/Notificacion'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap'
import "./detailModal.css";
import { formatDate } from 'utils/formatUtils';
import { toast } from 'react-toastify';
import { getNotificacionById } from '../../../../services/notificaciones';

interface Props {
    id?: string | number | null,
    showModal: boolean,
    handleCloseModal: () => void;

}

const DetailText = ({ title, detail }: { title: string, detail?: string }) => {
    return (
        <div>
            <h3 className="detail-title">{title}</h3>
            <p className="detail-text">{detail}</p>
        </div>
    )
}

export const DetailModal = ({ id, handleCloseModal, showModal, }: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [notificacion, setNotificacion] = useState<NotificacionI | undefined>()

    const loadNotificacion = async () => {
        if (showModal && id) {
            try {
                setIsLoading(true)
                const notificacion = await getNotificacionById(id)
                setNotificacion(notificacion)
            } catch (err) {
                toast.error("No fue posible cargar el detalle de la notificacion.")
            } finally {
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        loadNotificacion();
    }, [showModal])

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Notificación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ?
                        <div className="d-flex justify-content-center">
                            <Spinner></Spinner>
                        </div>
                        :
                        <Row>
                            <Col md={6} className="mb-3">
                                <DetailText title={"Tipo de notificación"} detail={notificacion?.notificationType?.name ?? "-"} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <DetailText title={"Nivel"} detail={notificacion?.schoolLevel?.name ?? "Todos"} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <DetailText title={"Grados"} detail={
                                    notificacion?.schoolYears && notificacion?.schoolYears.length > 0 ?
                                        notificacion?.schoolYears.map(ctEl => `${ctEl.name}`).join(" - ")
                                        :
                                        "Todos"
                                } />
                            </Col>
                            <Col md={6} className="mb-3">
                                <DetailText title={"Escuela"} detail={
                                    notificacion?.schools && notificacion?.schools.length > 0 ?
                                        notificacion?.schools.map(ctEl => `${ctEl.name} (CUE: ${ctEl.CUE})`).join(" - ")
                                        :
                                        "Todas"
                                } />
                            </Col>
                            <Col md={6} className="mb-3">
                                <DetailText title={"Título"} detail={notificacion?.title} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <DetailText
                                    title={"Programada"}
                                    detail={notificacion?.isProgrammed ? formatDate.stringDateTime(notificacion.sendAt) : "-"}
                                />
                            </Col>
                            <Col sm={12}>
                                <DetailText title={"Notificación"} detail={notificacion?.body} />
                            </Col>
                        </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={handleCloseModal}>
                        Volver
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <Row>
                <Col md={6}>
                    <DetailText title={"Tipo de notificación"} detail={notificacion.notificationType?.name ?? "-"} />
                </Col>

                <Col md={6}>
                    <DetailText title={"Tipo de notificación"} detail={notificacion.notificationType?.name ?? "-"} />
                </Col>

                <Col md={6}>
                    <DetailText title={"Tipo de notificación"} detail={notificacion.notificationType?.name ?? "-"} />
                </Col>
            </Row> */}
        </>
    )
}
