import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap'
import { getNovedadById } from 'services/novedades';
import { NovedadI } from 'interfaces/Novedad';
import { showErrorToast } from 'components/CustomAlerts/CustomToast';

interface Props {
    id?: string | number | null,
    showModal: boolean,
    handleCloseModal: () => void;

}

const DetailText = ({ title, detail }: { title: string, detail?: string }) => {
    return (
        <div>
            <h3 className="detail-title">{title}</h3>
            <p className="detail-text">{detail}</p>
        </div>
    )
}

export const DetailModalNews = ({ id, handleCloseModal, showModal, }: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [novedad, setNovedad] = useState<NovedadI | undefined>()

    const loadNovedad = async () => {
        if (showModal && id) {
            try {
                setIsLoading(true)
                const novedad = await getNovedadById(id)
                setNovedad(novedad)
            } catch (err) {
               showErrorToast("No fue posible cargar el detalle de la novedad.")
            } finally {
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        loadNovedad();
    }, [showModal])

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Novedad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ?
                        <div className="d-flex justify-content-center">
                            <Spinner></Spinner>
                        </div>
                        :
                        <Row>
                            <Col md={6} className="mb-3">
                                <DetailText title={"Título"} detail={novedad?.title ?? "-"} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <DetailText title={"Subtítulo"} detail={novedad?.subtitle ?? "-"} />
                            </Col>
                            <Col md={12} className="mb-3">
                                <DetailText title={"Descripción"} detail={novedad?.description} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <DetailText title={"Link"} detail={novedad?.link} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <DetailText title={"Nivel"} detail={novedad?.schoolLevels.map(ctEl => `${ctEl.name}`).join(" - ")} />
                            </Col>
                        </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={handleCloseModal}>
                        Volver
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
