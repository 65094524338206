import { EscuelaI } from "interfaces/Escuela";

export interface infoFetchGetSchoolExI {
    id: number | string;
    name: string;
    CUE: string;
    description: string;
}

class Escuela implements EscuelaI {
    id: number | string;
    name: string;
    CUE: string;
    description: string;

    constructor(infoFetch: infoFetchGetSchoolExI) {
        this.id = infoFetch.id;
        this.name = infoFetch.name;
        this.CUE = infoFetch.CUE;
        this.description = infoFetch.description;
    }
}

export const makeEscuela = (infoFetch: infoFetchGetSchoolExI) => {
    return new Escuela(infoFetch)
}