import React from 'react'
import { Col, Row } from 'react-bootstrap'
import SignInForm from '../../components/auth/SignInForm'

export const AccesoRestringido = () => {
    const withTraditionalForm = process.env.REACT_APP_SHOW_TRADITIONAL_LOGIN_FORM == "true"

    return (
        <section className="login-section" style={{ overflowX: "hidden" }}>
            <Row>
                <Col>
                    <Row>
                        <Col lg={5} md={6} sm={12} className="d-flex flex-column justify-content-center">
                            <div className="login-text-container">
                                <h1>Página restringida</h1>
                                <p>Usuario no habilitado</p>
                            </div>
                        </Col>
                        <Col lg={7} md={6} sm={12}>
                            <div className="login-hero-image-container">
                                <img src="/assets/img/acceso-restringido.svg" alt="Acceso restringido" />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    )
}

