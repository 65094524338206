import { log } from "console";
import { HistorialMensualNotificacionesI } from "interfaces/HistorialMensualNotificaciones";
import { HistorialMensualNovedadesI } from "interfaces/HistorialMensualNovedades";
import { HistorialTotalI } from "interfaces/HistorialTotal";
import { MetricasI } from "interfaces/Metricas";
import { NotificacionI } from "interfaces/Notificacion";
import { NovedadI } from "interfaces/Novedad";

export interface infoFetchGetMetricasI {

    ultima_notificacion: NotificacionI | null;
    ultima_novedad: NovedadI  | null;
    historial_notificaciones_mensual: HistorialMensualNotificacionesI;
    historial_novedades_mensual: HistorialMensualNovedadesI;
    historial_total:  infoFetchGetHistorialTotalI;
}


export interface infoFetchGetHistorialTotalI {
    notificaciones_enviadas: number,
    novedades_publicadas: number,
}
class Metricas implements MetricasI {

    ultimaNotificacion: NotificacionI  | null;
    ultimaNovedad: NovedadI  | null;
    historialNotificacionesMensual: HistorialMensualNotificacionesI;
    historialNovedadesMensual: HistorialMensualNovedadesI;
    historialTotal:  HistorialTotalI;

    constructor(infoFetch: infoFetchGetMetricasI) {

        this.ultimaNotificacion = infoFetch.ultima_notificacion;
        this.ultimaNovedad = infoFetch.ultima_novedad;
        this.historialNotificacionesMensual = infoFetch.historial_notificaciones_mensual;
        this.historialNovedadesMensual = infoFetch.historial_novedades_mensual;
        this.historialTotal = {     
            notificacionesEnviadas: infoFetch.historial_total.notificaciones_enviadas,
            novedadesPublicadas: infoFetch.historial_total.novedades_publicadas }

    }
}

export const makeMetricas = (infoFetch: infoFetchGetMetricasI) => {
    console.log(infoFetch);
    return new Metricas(infoFetch)
}