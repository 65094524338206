import React from 'react'
import { Col, Row } from 'react-bootstrap'
import "./../Login/login.css"
import SignInForm from '../../components/auth/SignInForm'

export const PaginaNoEncontrada = () => {

    return (
        <section className="login-section mt-5" style={{ overflowX: "hidden" }}>
            <Row>
                <Col>
                    <Row className="mt-5">
                        <Col lg={5} md={6} sm={12} className="d-flex flex-column justify-content-center">
                            <div className="login-text-container">
                                <h1>Página no encontrada</h1>
                                <p>Error 404</p>
                            </div>
                        </Col>
                        <Col lg={7} md={6} sm={12}>
                            <div className="login-hero-image-container">
                                <img src="/assets/img/pagina-no-encontrada.svg" alt="Página no encontrada" />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    )
}
