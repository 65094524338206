import { store } from "./store/store";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import { UtilsProvider } from "./context/UtilsProvider";
import reportWebVitals from "./reportWebVitals";
import { AxiosInterceptor } from "./services/request/authInterceptor"

AxiosInterceptor()

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <UtilsProvider>
          <AuthProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </AuthProvider>
        </UtilsProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
