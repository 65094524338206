import { infoFetchGetMetricasI, makeMetricas } from "dtos/metricas"
import { sendRequest } from "./request/request.service"
import { ENDPOINTS } from "./endpoints"
import { servicesUtils } from "./utils/services.utils"

export const getMetricas = async (params: any) => {
    const elemento = await sendRequest<infoFetchGetMetricasI>({
        method: "GET",
        uri: ENDPOINTS.STATISTICS,
        params: servicesUtils.removeNullParamsInFilters(params),
    })
    return elemento ? makeMetricas(elemento) : undefined
}