import * as yup from "yup";
import { DEFAULT_MESSAGES } from "../defaultMessages";

const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/; // Regex para validar formato de hora HH:mm


export const createNovedadesSchema = yup.object({
    niveles_id: yup.array().of(yup.number())
    .required(DEFAULT_MESSAGES.REQUIRED),
    novedad_titulo: yup.string().required(DEFAULT_MESSAGES.REQUIRED),
    novedad_subtitulo: yup.string().required(DEFAULT_MESSAGES.REQUIRED),
    novedad_descripcion: yup.string().required(DEFAULT_MESSAGES.REQUIRED),
    link: yup.string().optional(),
    fecha: yup.date().when(
        ['es_programada'],
        {
            is: (es_programada: boolean) => es_programada,
            then: (schema) => schema.required(DEFAULT_MESSAGES.REQUIRED),
            otherwise: (schema) => schema.nullable(),
        }
    ),
    hora: yup.string().when(
        ['es_programada'],
        {
            is: (es_programada: boolean) => es_programada,
            then: (schema) => schema
                .matches(timeRegex, DEFAULT_MESSAGES.INVALID_TIME)
                .required(DEFAULT_MESSAGES.REQUIRED),
            otherwise: (schema) => schema.nullable(),
        }
    ),
});
