import { infoFetchGetAnioNivelI, makeAnioNivel } from "dtos/aniosNivel"
import { sendRequest } from "./request/request.service"
import { fetchingByIdPropsI, fetchingPropsI } from "./request/request.interface"
import { servicesUtils } from "./utils/services.utils"
import { ENDPOINTS } from "./endpoints"

export const getAniosEscuela = async ({ params, signal }: fetchingPropsI) => {
    const response = await sendRequest<infoFetchGetAnioNivelI[]>({
        method: "GET",
        uri: ENDPOINTS.SCHOOL_YEARS,
        params: servicesUtils.removeNullParamsInFilters(params),
        signal,
    })
    return response.map((ctElemento) => makeAnioNivel(ctElemento))
}

export const getAniosEscuelaByNivel = async ({id, params, signal }: fetchingByIdPropsI) => {
    const response = await sendRequest<infoFetchGetAnioNivelI[]>({
        method: "GET",
        uri: ENDPOINTS.SCHOOL_YEARS + "/all/" + id,
        params: servicesUtils.removeNullParamsInFilters(params),
        signal,
    })
    return response.map((ctElemento) => makeAnioNivel(ctElemento))
}