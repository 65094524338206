import { RolI } from "interfaces/Rol";

export interface infoFetchGetRolesI {
    id: number | string;
    name: string;
    description: string;
}

class Role implements RolI {
    id: number | string;
    name: string;
    description: string;

    constructor(infoFetch: infoFetchGetRolesI) {
        this.id = infoFetch.id;
        this.name = infoFetch.name;
        this.description = infoFetch.description;
    }
}

export const makeRole = (infoFetch: infoFetchGetRolesI) => {
    return new Role(infoFetch)
}