import { Col, Container, Image, Row } from "react-bootstrap";
import logoFooter from "../../images/logo-ba-footer.png";
import logoFooterCiudad from "../../images/logo-bac.svg";
import iconFacebook from "../../images/social-icons/facebook-icon.svg";
import iconTwitter from "../../images/social-icons/twitter-icon.svg";
import iconInstagram from "../../images/social-icons/instagram-icon.svg";
import iconYoutube from "../../images/social-icons/youtube-icon.svg";
import iconWhatsapp from "../../images/social-icons/whatsapp-icon.svg";
import "./footer.css";

export const APP_VERSION = '1.2.2';

export const Footer = () => {
  return (
    <footer className="footer container-fluid">
      <div className="footer-container">
        <div className="d-flex flex-row justify-content-between">
          <Col md={6} sm={6}>
            <Image
              src={logoFooter}
              alt="Logo Vamos Buenos Aires"
              className="footer-hero-logo"
            />
          </Col>

          <Col md={6} sm={6} className="footer-icon-container">
            <a href={"*"} target="_blank" rel="noreferrer">
              <Image
                src={iconFacebook}
                alt="Facebook"
                className="footer-social-icon"
              />
            </a>

            <a href={"*"} target="_blank" rel="noreferrer">
              <Image
                src={iconTwitter}
                alt="Twitter"
                className="footer-social-icon"
              />
            </a>

            <a href={"*"} target="_blank" rel="noreferrer">
              <Image
                src={iconInstagram}
                alt="Instagram"
                className="footer-social-icon"
              />
            </a>

            <a href={"*"} target="_blank" rel="noreferrer">
              <Image
                src={iconYoutube}
                alt="Youtube"
                className="footer-social-icon"
                style={{ height: "20px" }}
              />
            </a>

            <a href={"*"} target="_blank" rel="noreferrer">
              <Image
                src={iconWhatsapp}
                alt="Whatsapp"
                className="footer-social-icon"
              />
            </a>
          </Col>
        </div>

        <hr className="hr-light-full"></hr>

        <Row className="d-flex justify-content-between">
          <Col md={4} className="align-content-center">
            <div className="d-flex flex-grow-1 justify-content-start">
              <Image
                src={logoFooterCiudad}
                alt="Logo Buenos Aires Ciudad"
                className="logo-footer"
              />
            </div>
          </Col>
          <Col md={8}>
            <Row className="d-flex justify-content-end">
                            <Col md={3}>
                                <div className="footer-puesto-container">
                                    <p className="mb-0 small footer-puesto">Ministerio de Educación</p>
                                    <p className="footer-puesto-nombre">Mercedes Miguel</p>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="footer-puesto-container middle">
                                    <p className="mb-0 small footer-puesto">Subsecretaría de <br/> Tecnología Educativa</p>
                                    <p className="footer-puesto-nombre">Ignacio Sanguinetti</p>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="footer-puesto-container last">
                                    <p className="mb-0 small footer-puesto">Dirección General de Proyectos y Tecnología Educativa</p>
                                    <p className="footer-puesto-nombre">Mariano Pérez Alfaro</p>                                
                                </div>
                            </Col>
                        </Row>
          </Col>
        </Row>

        <hr className="hr-light-full"></hr>

        <Row>
          <p className="footer-legals">
            Todos los derechos reservados © 2024 Dirección General de Proyectos y Tecnología Educativa
          </p>
          {/* <p className="footer-legals m-0">Versión {APP_VERSION}</p> */}
        </Row>
      </div>
    </footer>
  );
};
