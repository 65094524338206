import { makeEstadoNotificacion } from "dtos/estadosNotificacion";
import { infoFetchGetTiposNotificacionI, makeTipoNotificacion } from "dtos/tiposNotificacion";
import { EstadoNotificacionI } from "interfaces/EstadoNotificacion";
import { ENDPOINTS } from "services/endpoints";
import { fetchingPropsI, getResponseWithPagination } from "./request/request.interface";
import { sendRequest } from "./request/request.service";
import { servicesUtils } from "./utils/services.utils";
import { infoFetchGetNivelesEscuelaI, makeNivelEscuela } from "dtos/nivelesEscuela";
import { infoFetchGetAnioNivelI, makeAnioNivel } from "dtos/aniosNivel";
import { infoFetchGetRolesI, makeRole } from "dtos/roles";
import { infoFetchGetAreaI, makeArea } from "dtos/areas";
import { EstadoNovedadI } from "interfaces/EstadoNovedad";
import { makeEstadoNovedad } from "dtos/estadoNovedad";

export const getTiposNotificacion = async ({ params, signal }: fetchingPropsI) => {
    const response = await sendRequest<infoFetchGetTiposNotificacionI[]>({
        method: "GET",
        uri: ENDPOINTS.NOTIFICATION_TYPES,
        params: servicesUtils.removeNullParamsInFilters(params),
        signal,
    })
    return response.map((ctElemento) => makeTipoNotificacion(ctElemento))
}

export const getEstadosNotificacion = async ({ params, signal }: fetchingPropsI) => {
    const response = await sendRequest<EstadoNotificacionI[]>({
        method: "GET",
        uri: ENDPOINTS.NOTIFICATION_STATES,
        params: servicesUtils.removeNullParamsInFilters(params),
        signal,
    })
    return response.map((ctElemento) => makeEstadoNotificacion(ctElemento))
}

export const getNivelesEscuela = async ({ params, signal }: fetchingPropsI) => {
    const response = await sendRequest<infoFetchGetNivelesEscuelaI[]>({
        method: "GET",
        uri: ENDPOINTS.SCHOOL_LEVELS,
        params: servicesUtils.removeNullParamsInFilters(params),
        signal,
    })
    return response.map((ctElemento) => makeNivelEscuela(ctElemento))
}



export const getEscuelas = async ({ params, signal }: fetchingPropsI) => {

    const response = await sendRequest<any[]>({
        method: "GET",
        uri: ENDPOINTS.SCHOOLS,
        params: servicesUtils.removeNullParamsInFilters(params),
        signal,
    })
    console.log({ response })
    // return response.map((ctElemento) => makeAnioNivel(ctElemento))
}

export const getRol = async ({ params, signal }: fetchingPropsI) => {
    const response = await sendRequest<infoFetchGetRolesI[]>({
        method: "GET",
        uri: ENDPOINTS.ROLES,
        params: servicesUtils.removeNullParamsInFilters(params),
        signal,
    })
    return response.map((ctElemento) => makeRole(ctElemento))
}

export const getArea = async ({ params, signal }: fetchingPropsI) => {
    const response = await sendRequest<infoFetchGetAreaI[]>({
        method: "GET",
        uri: ENDPOINTS.AREAS,
        params: servicesUtils.removeNullParamsInFilters(params),
        signal,
    })
    return response.map((ctElemento) => makeArea(ctElemento))
}
export const getEstadosNovedad = async ({ params, signal }: fetchingPropsI) => {
    const response = await sendRequest<EstadoNovedadI[]>({
        method: "GET",
        uri: ENDPOINTS.NEWS_STATES,
        params: servicesUtils.removeNullParamsInFilters(params),
        signal,
    })
    return response.map((ctElemento) => makeEstadoNovedad(ctElemento))
}