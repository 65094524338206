import React from 'react'
import { Col, Form } from 'react-bootstrap'

interface radioOption {
    value: any;
    label: string;
}

interface Props {
    title?: string;
    options: radioOption[]
    required?: boolean;
    id: string | number;
    onChange: any,
    flexDirection?: "col" | "row"
    optionElementColsConfig?: {
        sm?: number;
        md?: number;
        lg?: number;
    }
    values: any;
    style?: { [key: string]: any };
}

export const Radio = ({
    title,
    options,
    required,
    id,
    onChange = () => { },
    optionElementColsConfig = {},
    values,
    style,
}: Props) => {

    const handleChange = (option: radioOption) => {
        const customEvent = { target: { id, value: option.value } }
        onChange(customEvent)
    }

    return (
        <div style={style}>
            <h5 className="label">{title} {required && "*"}</h5>
            <Form.Group className="mb-3 row" controlId={`${id}`}>
                {options.map(ctOpcion =>
                    <Col sm={12} {...optionElementColsConfig} key={`radio-${id}-${ctOpcion.label}`}>
                        <label className="d-flex align-items-center">
                            <Form.Check
                                type={"radio"}
                                className="form-check-inline radioCustom"
                                id={`${id}-${ctOpcion.label}`}
                                name={`${id}`}
                            >
                                <Form.Check.Input
                                    checked={ctOpcion.value == values[id]}
                                    className="btn-check"
                                    type={"radio"}
                                    name={`${id}`}
                                    id={`${id}-${ctOpcion.label}`}
                                    onChange={() => handleChange(ctOpcion)} />
                            </Form.Check>
                            {ctOpcion.label}
                        </label>
                    </Col>
                )}
            </Form.Group>
        </div>
    )
}
