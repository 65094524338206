import { LISTA_COLORES_ICONOS } from './LISTA_COLORES_ICONOS';

interface Props {
    color?: (typeof LISTA_COLORES_ICONOS)[keyof typeof LISTA_COLORES_ICONOS];
    size?: number;
}

export const OptionsIcon = ({
    color = LISTA_COLORES_ICONOS.BLUE_ACTIVO,
    size = 24,
}: Props) => {
    return (
        <svg id="Acciones_Borrar" data-name="Acciones / Borrar" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path id="Trazado_41" data-name="Trazado 41" d="M0,0H24V24H0Z" fill="none" />
        <g id="Grupo_35913" data-name="Grupo 35913" transform="translate(10 3)">
            <circle id="Elipse_1405" data-name="Elipse 1405" cx="2" cy="2" r="2" fill={color}/>
            <circle id="Elipse_1406" data-name="Elipse 1406" cx="2" cy="2" r="2" transform="translate(0 14)" fill={color}/>
            <circle id="Elipse_1407" data-name="Elipse 1407" cx="2" cy="2" r="2" transform="translate(0 7)" fill={color}/>
        </g>
        </svg>
    )
}