import { formatOptions, getOptionsFromResponse, optionsFormatConfigI, SelectOptionI } from 'components/utils/selectUtils/selectUtils';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import AsyncSelectComponent from 'react-select/async';
import { SelectedElement } from './components/SelectedElement';
import { ExclamationIcon } from 'assets/icons/ExclamationIcon';
import { LISTA_COLORES_ICONOS } from 'assets/icons/LISTA_COLORES_ICONOS';

interface Props {
    id: number | string;
    optionsFormatConfig?: optionsFormatConfigI,
    getter: (params: any) => Promise<any>;
    paramKey: string;
    arrElementsPath?: string;
    isMulti?: boolean;
    values: any;
    onChange: any,
    variant?: "gray-container" | "white-container"
    title?: string,
    required?: boolean,
    disabled?: boolean,
    size?: "md" | "lg" | "sm",
    width?: string;
    selectedElementColsConfig?: {
        sm?: number;
        md?: number;
        lg?: number;
    }
    onBlur?: any,
    errors?: any,
    touched?: any,
    defaultSelectedOptions?: any;
    placeholder?: string;
    subtext?: string;
    noOptionsMessage?: string;
    emptySelectMessage?: string;
}

// optionsFormatConfig: Ejemplos de uso
// El select trabaja con opciones de la forma {label, value}[]
// Donde muestra el valor del label y envia el valor del value

// Si enviamos options con un formato distinto usamos optionsFormatConfig: Ej, enviamos {id, nombre}
// [{id: 1, nombre:"A"}, id:2, nombre"B"] -> optionsFormatConfig: { label: "nombre", value: "id" },

// Si queremos customizar lo que se muestra en el Select: Ej, enviamos {id, nombre}
// [{id: 1, nombre:"A"}, id:2, nombre"B"] -> optionsFormatConfig: { label: (option) => `{option.nombre} (${option.id})`, value: "id" },
// mostraria: A (1) y B (2)

export const AsyncSelect = ({
    id,
    values,
    onChange,
    optionsFormatConfig,
    arrElementsPath,
    getter,
    paramKey,
    isMulti,
    variant = "white-container",
    title,
    required,
    size = "md",
    width,
    selectedElementColsConfig = {},
    disabled,
    onBlur,
    errors,
    touched,
    placeholder,
    defaultSelectedOptions,
    subtext,
    noOptionsMessage,
    emptySelectMessage,
}: Props) => {
    const [selectedOptions, setSelectedOptions] = useState<SelectOptionI[]>([])

    const handleChange = (e: SelectOptionI) => {
        console.log("handleChange", { e })
        if (isMulti) {
            setSelectedOptions(st => {
                const newSt = [...st, e]
                const customEvent = { target: { name: id, value: newSt.map(ctOp => ctOp.value) } }
                onChange(customEvent)
                return newSt
            })
        } else {

        }
    }

    const getOptions = async (newValues: string) => {
        const rawResponse = await getter({ [paramKey]: newValues })

        const rawOptions = arrElementsPath ? getOptionsFromResponse({ response: rawResponse, path: arrElementsPath }) : rawResponse

        const formatedOptions = optionsFormatConfig ?
            formatOptions({ options: rawOptions, optionsFormatConfig })
            :
            rawOptions as SelectOptionI[]

        return formatedOptions.filter(ctOption => !selectedOptions.some(ctSelected => ctSelected.value == ctOption.value))
    }

    const handleRemoveSelectedOption = (option: SelectOptionI) => {
        setSelectedOptions(st => {
            const newSt = st.filter(ctOp => ctOp.value != option.value)
            const customEvent = { target: { name: id, value: newSt.map(ctOp => ctOp.value) } }
            onChange(customEvent)
            return newSt;
        })
    }

    const showError = touched && touched[id] && errors && errors[id]

    const loadDefaultValues = () => {
        if (defaultSelectedOptions) {
            const formatedOptions = optionsFormatConfig ?
                formatOptions({ options: defaultSelectedOptions, optionsFormatConfig })
                :
                defaultSelectedOptions as SelectOptionI[]
            setSelectedOptions(formatedOptions)
        }
    }

    useEffect(() => {
        loadDefaultValues()
    }, [defaultSelectedOptions])

    return (
        <label className={`select-label ${variant ? variant : ""} ${size ? size : ""} ${showError ? "error" : ""}`} style={{ width }}>
            {title && <h5 className="label">{title} {required && "*"}</h5>}

            <AsyncSelectComponent
                value={isMulti ? "" : values[id]}
                onChange={handleChange}
                loadOptions={getOptions}
                classNamePrefix={`custom-select`}
                isDisabled={disabled}
                placeholder={placeholder}
                noOptionsMessage={(params: any) => {
                    return (!params.inputValue || params.inputValue == "") ?
                        (emptySelectMessage ?? "Ingresar un valor")
                        :
                        (noOptionsMessage ?? "No hay opciones")
                }}
            />
            {subtext && <p className="small">{subtext}</p>}
            {showError && <div className="d-flex align-items-center gap-1 mt-1">
                <ExclamationIcon size={14} color={LISTA_COLORES_ICONOS.ERROR} />
                <p className="component-error-msg mb-0">
                    {errors[id]}
                </p>
            </div>}
            <Row className="mt-2">
                {selectedOptions.map(ctValue =>
                    <Col sm={12} className="mb-2" {...selectedElementColsConfig}>
                        <SelectedElement
                            handleRemove={() => handleRemoveSelectedOption(ctValue)}
                            label={ctValue.label}
                        />
                    </Col>
                )}
            </Row>
        </label>
    )
}
