import React, { ReactNode } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { ExclamationIcon } from 'assets/icons/ExclamationIcon';
import { LISTA_COLORES_ICONOS } from 'assets/icons/LISTA_COLORES_ICONOS';
import ReactSelect from 'react-select';
import { Select } from 'components/Select/Select';

interface Props {
    title?: string;
    required?: boolean;
    placeholder?: string;
    type?: "text" | "number" | "textarea" | "date" | "time";
    min?: number;
    max?: number;
    minDate?: string;
    disabled?: boolean;
    size?: "sm" | "lg",
    onChange: any;
    values: any;
    id: string;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    variant?: "gray-container" | "white-container";
    maxLength?: number;
    defaultHeight?: string;
    errors?: any;
    touched?: any;
    onBlur?: any,
    minutesStep?: number;
    minTime?: string;
    maxTime?: string;
}

export const Input = ({
    size,
    title,
    required,
    placeholder = "",
    type = "text",
    min,
    max,
    onChange = () => { },
    values,
    disabled,
    id,
    startIcon,
    endIcon,
    variant = "white-container",
    maxLength,
    defaultHeight,
    onBlur,
    errors,
    touched,
    minutesStep,
    minTime,
    maxTime,
    minDate,
}: Props) => {

    const handleChange = (event: any) => {
        if (maxLength && event.target.value.length > maxLength) return
        onChange(event)
    }

    const handleBlur = () => {
        const event = { target: { id, value: id } }
        if (onBlur) onBlur(event)
    }

    const showError = touched && touched[id] && errors && errors[id]

    const renderOptionsWithStep = (minutesStep: number) => {
        const minTimeSplitted = minTime?.split(":") //MM:HH
        const maxTimeSplitted = maxTime?.split(":") //MM:HH

        // console.log({
        //     minTimeSplitted,
        //     maxTimeSplitted,
        //     minutesStep,
        //     minTime,
        //     maxTime,
        // })

        const iMinute = Number(minTimeSplitted ? minTimeSplitted[1] : 0);
        const iHour = Number(minTimeSplitted ? minTimeSplitted[0] : 0);
        const fMinute = Number(maxTimeSplitted ? maxTimeSplitted[1] : 0);
        const fHour = Number(maxTimeSplitted ? maxTimeSplitted[0] : 24);

        const totalMinutes = fMinute + (fHour * 60);

        // console.log({ totalMinutes, fMinute, fHour, iHour, iMinute })

        const opciones = []

        for (let minutes = iHour * 60 + iMinute; minutes < totalMinutes; minutes += minutesStep) {
            const hour = Math.floor(minutes / 60);
            const minute = minutes % 60;

            const formattedTime = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;

            // console.log("ACA", { minutes, totalMinutes, formattedTime })
            opciones.push({ value: formattedTime, label: formattedTime });
        }
        return opciones
    }

    return (
        <label className={`select-label ${variant ? variant : ""} ${showError ? "error" : ""}`} style={{ width: size }}>
            <div className="d-flex justify-content-between">
                {title && <h5 className="label">{title} {required && "*"}</h5>}

                {maxLength && <p className="input-length">{values[id] ? values[id].length : 0}/{maxLength}</p>}
            </div>

            {
                type == "time" && minutesStep ?
                    <Select
                        options={renderOptionsWithStep(minutesStep)}
                        id={id}
                        values={values}
                        variant="white-container"
                        onChange={handleChange}
                        disabled={disabled}
                        onBlur={handleBlur}
                        height={"48px"}
                        placeholder={placeholder}
                    />
                    :
                    <InputGroup className="input-container" style={{ width: size }}>
                        {startIcon && <div className="start-icon-container">{startIcon}</div>}
                        <Form.Control
                            name={id}
                            className="form-input-custom"
                            placeholder={placeholder}
                            aria-label={title}
                            type={type}
                            onChange={handleChange}
                            size={size}
                            value={values[id]}
                            disabled={disabled}
                            as={type == "textarea" ? "textarea" : undefined}
                            style={{ height: defaultHeight }}
                            onBlur={handleBlur}
                            {...(type === "number" ? { min, max } : {})}
                            {...(type === "date" ? { min: minDate } : {})}
                        />
                        {endIcon && <div className="end-icon-container">{endIcon}</div>}
                    </InputGroup>
            }

            {showError && <div className="d-flex align-items-center gap-1 mt-1">
                <ExclamationIcon size={14} color={LISTA_COLORES_ICONOS.ERROR} />
                <p className="component-error-msg mb-0">
                    {errors[id]}
                </p>
            </div>}
        </label>
    )
}
