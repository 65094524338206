import { useEffect, useRef } from "react";

const useController = () => {
  const controller = useRef(new AbortController());

  useEffect(() => {
    controller.current = new AbortController();
    return () => {
      controller.current.abort();
    }
  }, []);

  return controller;
};

export default useController;
