import { ListGroup, ListGroupItem } from "react-bootstrap";
import { toast } from "react-toastify";

const Msg = ({ elements }) => (
  <ListGroup>
    {elements.map((e, i) => (
      <ListGroupItem key={i}>{e}</ListGroupItem>
    ))}
  </ListGroup>
);

const formatSuccess = (message) => {
  if (Array.isArray(message) && message.length > 1) {
    toast.success(<Msg elements={message} />);
    return;
  } else if (Array.isArray(message) && message.length === 1) {
    toast.success(message[0]);
    return;
  }
  toast.success(message);
};

const formatError = (
  error,
  defaultMessage = "Hubo un error, intente nuevamente"
) => {
  if (!error?.status || !error?.message) {
    toast.error(defaultMessage);
    return;
  }
  if (Array.isArray(error.message) && error.message.length > 1) {
    toast.error(<Msg elements={error.message} />);
    return;
  } else if (Array.isArray(error.message) && error.message.length === 1) {
    toast.error(error.message[0]);
    return;
  }
  toast.error(error.message);
};

export { formatError, formatSuccess };
