
import { LISTA_COLORES_ICONOS } from './LISTA_COLORES_ICONOS';

interface Props {
    color?: (typeof LISTA_COLORES_ICONOS)[keyof typeof LISTA_COLORES_ICONOS];
    size?: number;
}

export const ExclamationIcon = ({
    color = LISTA_COLORES_ICONOS.BLUE_ACTIVO,
    size = 20,
}: Props) => {
    return (
        <svg stroke={color} fill={color} strokeWidth="0" viewBox="0 0 16 16" height={size} width={size} xmlns="http://www.w3.org/2000/svg"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2"></path></svg>
    )
}
