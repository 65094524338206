import { UsuarioI } from 'interfaces/Usuario';
import { Dropdown } from 'react-bootstrap';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'routes/privateRoutes';
import { useState } from 'react';
import { UserModal } from '../UserModal/UserModal'; 
import { ModalDelete } from 'components/ModalDelete/ModalDelete';
import { OptionsIcon } from 'assets/icons/OptionsIcon';

interface Props {
    usuario: UsuarioI;
    handleDelete: (id: number | string) => void;
    searchUsuarios: () => void,
}

const cutText = (text: string | undefined, maxLength: number) => {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
};

export const UsuariosRow = ({ usuario, handleDelete, searchUsuarios }: Props) => {
    const navigate = useNavigate();
    const [showUserModal, setShowUserModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleClickPerfil = () => {
        navigate(PRIVATE_ROUTE.USUARIOS + "/" + usuario.id);
    };

    const handleShowUserModal = () => {
        setShowUserModal(true);
    };

    const handleCloseModal = () => {
        setShowUserModal(false);
    };

    const handleShowDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleConfirmDelete = () => {
        handleDelete(usuario.id);
        handleCloseDeleteModal();
    };

    return (
        <>
            <tr className='table-row' key={`NotificacionesTable-${usuario.id}`}>
                <td>{usuario.name} {usuario.lastname}</td>
                <td>{usuario.email}</td>
                <td>{cutText(usuario.area?.description, 26)}</td>
                <td>{cutText(usuario.role?.description, 26)}</td>
                <td>{format(new Date(usuario.createdAt), 'dd/MM/yy')}</td>
                <td>
                    <Dropdown className="acciones-dropdown | d-flex justify-content-center">
                        <Dropdown.Toggle>
                            <OptionsIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleClickPerfil}>Ver usuario</Dropdown.Item>
                            <Dropdown.Item onClick={handleShowUserModal}>Editar</Dropdown.Item>
                            <Dropdown.Item onClick={handleShowDeleteModal}>Eliminar</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>

            <UserModal
                usuario={usuario}
                handleCloseModal={handleCloseModal}
                showModal={showUserModal}
                refreshPage={searchUsuarios}
                isEditMode={true}
            />

            <ModalDelete
                showModal={showDeleteModal}
                handleCloseModal={handleCloseDeleteModal}
                handleConfirmDelete={handleConfirmDelete}
                isUserDelete={true}
            />
        </>
    );
};
