import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { NovedadesRow } from './NovedadesRow';
import { NovedadI } from 'interfaces/Novedad';
import { DetailModalNews } from '../components/detailModalNews';

interface Props {
    elementos: NovedadI[],
    handleDelete: (id: number | string) => void,
    handleEdit: (novedad: NovedadI) => void,
}

export const NovedadesTable = ({
    elementos = [],
    handleDelete,
    handleEdit,
}: Props) => {
    const [selectedNovedadId, setSelectedNovedadId] = useState<null | number | string>(null)

    const [showDetailModal, setShowDetailModal] = useState(false)

    const handleShowDetailModalNews = (id: number | string) => {
        setSelectedNovedadId(id)
        setShowDetailModal(true)
    }

    const handleCloseModal = () => {
        setShowDetailModal(false)
    }

    return (
        <>
            <Table striped bordered className='table-container'>
                <thead>
                    <tr className='table-heading'>
                        <th>Título</th>
                        <th>Subtítulo</th>
                        <th>Nivel</th>
                        <th>Fecha de publicación</th>
                        <th>Estado</th>
                        <th className='text-center'>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {elementos.map(ctEl =>
                        <NovedadesRow
                            novedad={ctEl}
                            handleShowDetailModalNews={handleShowDetailModalNews}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                        />)}
                </tbody>
            </Table>
            {
                <DetailModalNews
                    id={selectedNovedadId}
                    handleCloseModal={handleCloseModal}
                    showModal={showDetailModal}
                />
            }
        </>
    )
}
