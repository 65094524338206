import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AreaI } from "interfaces/Area";
import { EstadoNotificacionI } from "interfaces/EstadoNotificacion";
import { NivelEscuelaI } from "interfaces/NivelEscuela";
import { TipoNotificacionI } from "interfaces/TipoNotificacion";
import { getEstadosNotificacion, getNivelesEscuela, getTiposNotificacion, getRol, getArea, getEstadosNovedad } from 'services/general';
import { RolI } from "interfaces/Rol";
import { getResponseWithPagination } from "services/request/request.interface";
import { EstadoNovedadI } from "interfaces/EstadoNovedad";

export interface appDataState {
    tiposNotificacion: TipoNotificacionI[] | undefined,
    estadosNotificacion: EstadoNotificacionI[] | undefined,
    estadosNovedad: EstadoNovedadI[] | undefined,
    roles: RolI[] | undefined,
    areas: AreaI[] | undefined,
    nivelesEscuela: NivelEscuelaI[] | undefined;
    loadingAppData: {
        tiposNotificacion: boolean,
        estadosNotificacion: boolean,
        estadosNovedad: boolean,
        nivelesEscuela: boolean,
        roles: boolean,
        areas: boolean,
    },
    errorAppData: {
        tiposNotificacion: undefined | string,
        estadosNotificacion: undefined | string,
        estadosNovedad: undefined | string,
        nivelesEscuela: undefined | string,
        roles: undefined | string,
        areas: undefined | string,
    }
}

const defaultState: appDataState = {
    tiposNotificacion: undefined,
    estadosNotificacion: undefined,
    estadosNovedad: undefined,
    nivelesEscuela: undefined,
    roles: undefined,
    areas: undefined,
    loadingAppData: {
        tiposNotificacion: false,
        estadosNotificacion: false,
        estadosNovedad: false,
        nivelesEscuela: false,
        roles: false,
        areas: false,
    },
    errorAppData: {
        tiposNotificacion: undefined,
        estadosNotificacion: undefined,
        estadosNovedad: undefined,
        nivelesEscuela: undefined,
        roles: undefined,
        areas: undefined,
    }
};

const initialState: appDataState = defaultState;

export const fetchTiposNotificacion = createAsyncThunk<TipoNotificacionI[]>('appData/fetchTiposNotificacion', async () => {
    return await getTiposNotificacion({});
});

export const fetchNivelesEscuela = createAsyncThunk<NivelEscuelaI[]>('appData/fetchNivelesEscuela', async () => {
    return await getNivelesEscuela({});
});


export const fetchEstadosNotificacion = createAsyncThunk<EstadoNotificacionI[]>('appData/fetchEstadosNotificacion', async () => {
    return await getEstadosNotificacion({});
});

export const fetchRoles = createAsyncThunk<RolI[]>('appData/fetchRoles', async () => {
    return await getRol({});
});

export const fetchAreas = createAsyncThunk<AreaI[]>('appData/fetchAreas', async () => {
    return await getArea({});
});

export const fetchEstadosNovedad = createAsyncThunk<EstadoNovedadI[]>('appData/fetchEstadosNovedad', async () => {
    return await getEstadosNovedad({});
});


export const appDataSlice = createSlice({
    name: "appData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTiposNotificacion.pending, (state) => {
                state.loadingAppData.tiposNotificacion = true;
                state.errorAppData.tiposNotificacion = undefined;
            })
            .addCase(fetchTiposNotificacion.fulfilled, (state, action: PayloadAction<TipoNotificacionI[]>) => {
                state.loadingAppData.tiposNotificacion = false;
                state.tiposNotificacion = action.payload;
            })
            .addCase(fetchTiposNotificacion.rejected, (state, action) => {
                state.loadingAppData.tiposNotificacion = false;
                state.errorAppData.tiposNotificacion = action.error.message;
            });

        builder
            .addCase(fetchNivelesEscuela.pending, (state) => {
                state.loadingAppData.nivelesEscuela = true;
                state.errorAppData.nivelesEscuela = undefined;
            })
            .addCase(fetchNivelesEscuela.fulfilled, (state, action: PayloadAction<NivelEscuelaI[]>) => {
                state.loadingAppData.nivelesEscuela = false;
                state.nivelesEscuela = action.payload;
            })
            .addCase(fetchNivelesEscuela.rejected, (state, action) => {
                state.loadingAppData.nivelesEscuela = false;
                state.errorAppData.nivelesEscuela = action.error.message;
            });

        builder
            .addCase(fetchEstadosNotificacion.pending, (state) => {
                state.loadingAppData.estadosNotificacion = true;
                state.errorAppData.estadosNotificacion = undefined;
            })
            .addCase(fetchEstadosNotificacion.fulfilled, (state, action: PayloadAction<EstadoNotificacionI[]>) => {
                state.loadingAppData.estadosNotificacion = false;
                state.estadosNotificacion = action.payload;
            })
            .addCase(fetchEstadosNotificacion.rejected, (state, action) => {
                state.loadingAppData.estadosNotificacion = false;
                state.errorAppData.estadosNotificacion = action.error.message;
            });

        builder
            .addCase(fetchRoles.pending, (state) => {
                state.loadingAppData.roles = true;
                state.errorAppData.roles = undefined;
            })
            .addCase(fetchRoles.fulfilled, (state, action: PayloadAction<RolI[]>) => {
                state.loadingAppData.roles = false;
                state.roles = action.payload;
            })
            .addCase(fetchRoles.rejected, (state, action) => {
                state.loadingAppData.roles = false;
                state.errorAppData.roles = action.error.message;
            });

        builder
            .addCase(fetchAreas.pending, (state) => {
                state.loadingAppData.areas = true;
                state.errorAppData.areas = undefined;
            })
            .addCase(fetchAreas.fulfilled, (state, action: PayloadAction<AreaI[]>) => {
                state.loadingAppData.areas = false;
                state.areas = action.payload;
            })
            .addCase(fetchAreas.rejected, (state, action) => {
                state.loadingAppData.areas = false;
                state.errorAppData.areas = action.error.message;
            });

            builder
            .addCase(fetchEstadosNovedad.pending, (state) => {
                state.loadingAppData.estadosNovedad = true;
                state.errorAppData.estadosNovedad = undefined;
            })
            .addCase(fetchEstadosNovedad.fulfilled, (state, action: PayloadAction<EstadoNovedadI[]>) => {
                state.loadingAppData.estadosNovedad = false;
                state.estadosNovedad = action.payload;
            })
            .addCase(fetchEstadosNovedad.rejected, (state, action) => {
                state.loadingAppData.estadosNovedad = false;
                state.errorAppData.estadosNovedad = action.error.message;
            });
    }
});

export default appDataSlice.reducer;
