export interface SelectOptionI {
    label: string,
    value: string,
    original: any
};

export interface optionsFormatConfigI {
    label: string | ((option: any) => string);
    value: string;
}

export interface formatOptionsI {
    options?: any[];
    optionsFormatConfig: optionsFormatConfigI;
}

export const formatOptions = ({ options = [], optionsFormatConfig }: formatOptionsI): SelectOptionI[] => {
    if (!optionsFormatConfig) return [];

    return options?.map(ctOp => ({
        label: (typeof (optionsFormatConfig.label) == "string") ? ctOp[optionsFormatConfig.label] : optionsFormatConfig.label(ctOp),
        value: ctOp[optionsFormatConfig.value],
        original: ctOp,
    }))
}

interface getOptionsFromResponseI {
    response: any;
    path: string;
}

export const getOptionsFromResponse = ({ response, path }: getOptionsFromResponseI) => {
    const arrPaths = path.split("/").filter(ctPath => ctPath != "")
    let options;

    const getOptions = (response: any, arrPaths: string[]) => {
        const isLastPath = arrPaths.length == 0;
        const ctPath = arrPaths[0]
        const ctResponse = response[ctPath]
        if (ctResponse != undefined && !isLastPath) {
            const newArrPaths: string[] = [...arrPaths]
            newArrPaths.shift()
            getOptions(ctResponse, newArrPaths)
        } else {
            options = response;
        }
    }

    getOptions(response, arrPaths)

    return options
}
